import { Injectable } from '@angular/core';

// export types
export const TYPE_HTML = 'html';
export const TYPE_CSV = 'csv';
export const TYPE_EXCEL = 'excel';
export const TYPE_IMAGE = 'image';
export const TYPE_PDF = 'pdf';

// export options
export const FONT_URL = 'https://cdn.flexmonster.com/fonts/NotoSans-Regular.ttf';
export const PAGE_ORIENTATION = 'landscape';
export const EXCEL_SHEET_NAME = 'Report';
export const FOOTER = '<div style="font-family: \'NotoSans-Regular\'; font-size: 12px;"> ' +
  '<p>##CURRENT-DATE##</p> <p style="float: right">##PAGE-NUMBER##</p></div>';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATETIME_FORMAT_WITH_SECONDS = 'DD.MM.YYYY HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_OPTIONS = {
  grid: { type: 'flat', showTotals: 'off', showGrandTotals: 'off' },
  datePattern: DATE_FORMAT,
  dateTimePattern: DATETIME_FORMAT_WITH_SECONDS,
  timePattern: TIME_FORMAT,
};

// icons
/* eslint-disable max-len */
const ICON_SAVE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M17 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zM7.1 4.4H14v4.2c0 .6-.4 1-1 1H8.1c-.6 0-1-.4-1-1V4.4zm11.7 15.2H5.2v-5.2c0-.9.7-1.6 1.6-1.6h10.4c.9 0 1.6.7 1.6 1.6v5.2z"/>' +
  '<path d="M11.6 5h1v4h-1zm4.427 9.427v1h-8v-1zm-.046 2.354v1h-8v-1z"/></svg>';

const ICON_EXPORT = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M12 1L8 5h3v9h2V5h3m2 18H6a2 2 0 01-2-2V9a2 2 0 012-2h3v2H6v12h12V9h-3V7h3a2 2 0 012 2v12a2 2 0 01-2 2z"/></svg>';

const ICON_PRINT = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0z"/>' +
  '<path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"/></svg>';

const ICON_EXPORT_HTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"/>' +
  '<path d="M7.3 15.6v-2.8h.6v1.1h.6v-1.1H9v2.8h-.6v-1.1h-.5v1.1h-.6zm2.8 0v-2.3h-.5v-.6h1.7v.6h-.6v2.3h-.6zm1.7 0v-2.8h.6v.6h.6v.6h-.6v1.7h-.6zM13 15v-1.1h.6V15H13zm1.1.6v-1.7h-.6v-.6h.6v-.6h.6v2.8h-.6zm1.1 0v-2.8h.6V15h1.1v.6h-1.7z"/></svg>';

const ICON_EXPORT_CSV = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"/>' +
  '<path d="M7.3 16.8v-4.4h2.6v.9H8.2v2.6h1.7v.9H7.3zm3.5 0v-.9h1.7V15h-1.7v-2.6h2.6v.9h-1.7v.9h1.7v2.6h-2.6zm3.5-.9v-3.5h.9v3.5h-.9zm.9.9v-.9h.8v.9h-.8zm.8-.9v-3.5h.9v3.5H16z"/></svg>';

const ICON_EXPORT_EXCEL = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"/>' +
  '<path d="M7.3 14.1v-1.7h.9v1.7h-.9zm0 2.7V15h.9v1.7h-.9zm.9-1.8v-.9h.9v.9h-.9zm.9-.9v-1.7h.9v1.7h-.9zm0 2.7V15h.9v1.7h-.9zm1.7 0v-4.4h.9v3.5h1.7v.9h-2.6zm3.5 0v-.9H16V15h-1.7v-2.6h2.6v.9h-1.7v.9h1.7v2.6h-2.6z"/></svg>';

const ICON_EXPORT_IMAGE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/></svg>';

const ICON_EXPORT_PDF = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"/>' +
  '<path d="M7.3 16.8v-4.4h2.6V15H8.2v1.7h-.9zm1.8-2.7v-.9h-.9v.9h.9zm1.7 2.7v-4.4h1.7v.9h-.9v2.6h.9v.9h-1.7zm1.7-.9v-2.6h.9v2.6h-.9zm1.8.9v-4.4h2.6v.9h-1.7v.9h.8v.8h-.9v1.7h-.8z"/></svg>';

const ICON_GRID = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M5 4h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2m0 4v4h6V8H5m8 0v4h6V8h-6m-8 6v4h6v-4H5m8 0v4h6v-4h-6z"/></svg>';

const ICON_CHARTS = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M3 22V8h4v14H3m7 0V2h4v20h-4m7 0v-8h4v8h-4z"/></svg>';

const ICON_CHART_COLUMN = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M3 22V8h4v14H3m7 0V2h4v20h-4m7 0v-8h4v8h-4z"/></svg>';

const ICON_CHART_BAR = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M2 3h14v4H2V3zm0 7h20v4H2v-4zm0 7h8v4H2v-4z"/></svg>';

const ICON_CHART_LINE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M3 14l.5.07L8.07 9.5a1.95 1.95 0 01.52-1.91c.78-.79 2.04-.79 2.82 0 .53.52.7 1.26.52 1.91l2.57 2.57.5-.07c.18 0 .35 0 .5.07l3.57-3.57C19 8.35 19 8.18 19 8a2 2 0 012-2 2 2 0 012 2 2 2 0 01-2 2c-.18 0-.35 0-.5-.07l-3.57 3.57c.07.15.07.32.07.5a2 2 0 01-2 2 2 2 0 01-2-2l.07-.5-2.57-2.57c-.32.07-.68.07-1 0L4.93 15.5 5 16a2 2 0 01-2 2 2 2 0 01-2-2 2 2 0 012-2z"/></svg>';

const ICON_CHART_SCATTER = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M7 11a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3m4-8a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3m5.6 11.6c1.65 0 3 1.34 3 3a3 3 0 01-3 3c-1.66 0-3-1.35-3-3a3 3 0 013-3z"/></svg>';

const ICON_CHART_PIE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M11 2v20c-5.1-.5-9-4.8-9-10s3.9-9.5 9-10m2 0v9h9c-.5-4.8-4.2-8.5-9-9m0 11v9c4.7-.5 8.5-4.2 9-9h-9z"/></svg>';

const ICON_CHART_STACKED_COLUMN = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M22 21H2V3h2v16h2v-2h4v2h2v-3h4v3h2v-2h4v4m-4-7h4v2h-4v-2m-6-8h4v3h-4V6m4 9h-4v-5h4v5M6 10h4v2H6v-2m4 6H6v-3h4v3z"/></svg>';

const ICON_CHART_COLUMN_LINE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M1.7 7l.4.1 4-4a2.05 2.05 0 01.5-1.7 1.75 1.75 0 012.5 0 1.85 1.85 0 01.5 1.7l2.2 2.2.4-.1c.2 0 .3 0 .4.1l3.1-3.1a.6.6 0 01-.1-.4 1.8 1.8 0 113.6 0 1.69 1.69 0 01-1.7 1.7c-.2 0-.3 0-.4-.1L14 6.5a.6.6 0 01.1.4 1.8 1.8 0 01-3.6 0l.1-.4-2.2-2.2a1.39 1.39 0 01-.9 0l-4 4 .1.4a1.8 1.8 0 11-3.6 0A1.69 1.69 0 011.7 7zM0 12.4h2.6V21H0z"/>' +
  '<path d="M5.6 8.8h2.5v12.3H5.6zm11.2-2.7h2.5V21h-2.5zm-5.6 5.3h2.4V21h-2.4z"/></svg>';

const ICON_CHART_MULTIPLE_VALUES = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M22 16v2H6V2h2v11.57l5.71-9 3.16 2.11 2.42-2.42 1.42 1.42-3.58 3.61-2.84-1.89L8.82 16M4 20V4H2v18h20v-2z"/></svg>';

const ICON_FORMAT = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M21.7 13.35l-1 1-2.05-2.05 1-1c.21-.22.56-.22.77 0l1.28 1.28c.22.21.22.56 0 .77M12 18.94l6.07-6.06 2.05 2.05L14.06 21H12v-2.06M4 2h14a2 2 0 012 2v4.17L16.17 12H12v4.17L10.17 18H4a2 2 0 01-2-2V4a2 2 0 012-2m0 4v4h6V6H4m8 0v4h6V6h-6m-8 6v4h6v-4H4z"/></svg>';

const ICON_FORMAT_CELLS = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M18 7h-4V4h4zm0 5h-4V9h4zm0 5h-4v-3h4zM12 7v10H2V4h10zm6-7H2a2 2 0 00-2 2v15a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2z" data-name="Layer 1"/></svg>';

const ICON_FORMAT_CONDITIONAL = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M18 5h-4V2h4M8 5V2h4v3m-6 5H2V7h4m0 8H2v-3h4M2 2h4v3H2M0 2v13a2 2 0 002 2h6V7h12V2a2 2 0 00-2-2H2a2 2 0 00-2 2m19.5 10.4L18.1 11z"/>' +
  '<path d="M19.5 12.4l-2.8 2.8-1.5-1.4 1.5-1.4-1.5-1.4 1.5-1.4 1.4 1.4 1.4 1.4zm-7.3 0l1.4 1.4-1.4 1.4-1.4-1.4-1.4-1.4 1.4-1.4 1.4-1.4 1.4 1.4-1.4 1.4z"/></svg>';

const ICON_OPTIONS = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  '<path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32a.488.488 0 00-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"/></svg>';

const ICON_FIELDS = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M3 3h14a2 2 0 012 2v7.08a6.01 6.01 0 00-4.32.92H11v4h1.08c-.11.68-.11 1.35 0 2H3a2 2 0 01-2-2V5c0-1.1.9-2 2-2m0 4v4h6V7H3m8 0v4h6V7h-6m-8 6v4h6v-4H3m19.78 6.32l-1.07-.82c.02-.17.04-.33.04-.5s-.01-.33-.04-.5l1.06-.82a.26.26 0 00.06-.32l-1-1.73c-.06-.13-.19-.13-.33-.13l-1.22.5c-.28-.18-.54-.35-.85-.47l-.19-1.32c-.01-.12-.12-.21-.24-.21h-2c-.12 0-.23.09-.25.21l-.19 1.32c-.3.13-.59.29-.85.47l-1.24-.5c-.11 0-.24 0-.31.13l-1 1.73c-.06.11-.04.24.06.32l1.06.82a4.193 4.193 0 000 1l-1.06.82a.26.26 0 00-.06.32l1 1.73c.06.13.19.13.31.13l1.24-.5c.26.18.54.35.85.47l.19 1.32c.02.12.12.21.25.21h2c.12 0 .23-.09.25-.21l.19-1.32c.3-.13.56-.29.84-.47l1.22.5c.14 0 .27 0 .34-.13l1-1.73a.26.26 0 00-.06-.32M18 19.5c-.83 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5 1.5.67 1.5 1.5-.66 1.5-1.5 1.5z"/></svg>';

const ICON_FULLSCREEN = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0z"/>' +
  '<path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg>';

const ICON_MINIMIZE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0z"/>' +
  '<path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/></svg>';

export const ICON_ARROW_EXPAND = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M2 20v2h20v-2h-9V5.83l5.5 5.5 1.42-1.41L12 2 4.08 9.92l1.42 1.41 5.5-5.5V20H2z"/></svg>';

export const ICON_ARROW_COLLAPSE = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M22 4V2H2v2h9v14.17l-5.5-5.5-1.42 1.41L12 22l7.92-7.92-1.42-1.41-5.5 5.5V4h9z"/></svg>';

export const ICON_BACK = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path fill="none" d="M0 0h24v24H0z"/>' +
  '<path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"/></svg>';

export const ICON_REFRESH = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '<path d="M17.65 6.35A7.958 7.958 0 0012 4a8 8 0 00-8 8 8 8 0 008 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18a6 6 0 01-6-6 6 6 0 016-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>';


export const ICONS = {
  save: ICON_SAVE,
  export: ICON_EXPORT,
  export_print: ICON_PRINT,
  export_csv: ICON_EXPORT_CSV,
  export_excel: ICON_EXPORT_EXCEL,
  export_html: ICON_EXPORT_HTML,
  export_image: ICON_EXPORT_IMAGE,
  export_pdf: ICON_EXPORT_PDF,
  charts: ICON_CHARTS,
  chart_column: ICON_CHART_COLUMN,
  charts_bar: ICON_CHART_BAR,
  charts_line: ICON_CHART_LINE,
  charts_scatter: ICON_CHART_SCATTER,
  charts_pie: ICON_CHART_PIE,
  charts_stacked_column: ICON_CHART_STACKED_COLUMN,
  charts_column_line: ICON_CHART_COLUMN_LINE,
  charts_multiple_values: ICON_CHART_MULTIPLE_VALUES,
  grid: ICON_GRID,
  format: ICON_FORMAT,
  format_number: ICON_FORMAT_CELLS,
  format_conditional: ICON_FORMAT_CONDITIONAL,
  options: ICON_OPTIONS,
  fields: ICON_FIELDS,
  fullscreen: ICON_FULLSCREEN,
  minimize: ICON_MINIMIZE,
};

@Injectable()
export class FlexmonsterPropertiesService {
}
