import { GridTypeEnum } from '../enums/grid-type.enum';
import { GridModel } from '../models/grid.model';

export const GRID_LIST: GridModel[] = [
  {name: GridTypeEnum.ONE_COLUMN, columns: 1},
  {name: GridTypeEnum.TWO_COLUMNS_50_50, columns: 2},
  {name: GridTypeEnum.TWO_COLUMNS_30_70, columns: 2},
  {name: GridTypeEnum.TWO_COLUMNS_70_30, columns: 2},
  {name: GridTypeEnum.THREE_COLUMNS_30_30_30, columns: 3},
  {name: GridTypeEnum.THREE_COLUMNS_25_50_25, columns: 3},
];
