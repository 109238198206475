import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: any): any {
        return new DatePipe(this.localeId).transform(value, 'H:mm') + ' h';
    }
}
