import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { BbsObjectModel } from '@base/modules/rest/bbs-object/model/bbs-object.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';
import { CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { ObjectDefinitionModel } from './model/object-definition.model';

@Injectable()
export class BbsObjectRestService implements PageableRestService<BbsObjectModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/bbs-objects';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  findAll(payload: { pagination: BeyondPaginationModel; includeNonMcm?: boolean }): Observable<PaginationResponseModel<BbsObjectModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
      includeNonMcm: payload.includeNonMcm ? payload.includeNonMcm : false,
    };
    return this.httpClient.get<PaginationResponseModel<BbsObjectModel>>(this.controllerUrl, {params: queryParams});
  }

  all(): Observable<ObjectDefinitionModel[]> {
    return this.httpClient.get<ObjectDefinitionModel[]>(`${this.controllerUrl}/all`);
  }

  findByIds(payload: { ids: number[] }): Observable<BbsObjectModel[]> {
    return this.httpClient.post<BbsObjectModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<BbsObjectModel> {
    return this.httpClient.get<BbsObjectModel>(`${this.controllerUrl}/${payload.id}`);
  }

  findByObjectName(payload: { bbsObjectName: string }): Observable<BbsObjectModel> {
    return this.httpClient.get<BbsObjectModel>(`${this.controllerUrl}/object-name/${payload.bbsObjectName}`);
  }

  findByVrstaDokumenta(payload: { vrstaDokumenta: string }): Observable<BbsObjectModel> {
    return this.httpClient.get<BbsObjectModel>(`${this.controllerUrl}/vrsta-dokumenta/${payload.vrstaDokumenta}`);
  }

  create(payload: { bbsObject: BbsObjectModel }): Observable<BbsObjectModel> {
    return this.httpClient.post<BbsObjectModel>(`${this.controllerUrl}`, payload.bbsObject);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.httpClient.delete<void>(`${this.controllerUrl}/${payload.id}`);
  }

  update(payload: { id: number; bbsObject: BbsObjectModel }): Observable<BbsObjectModel> {
    return this.httpClient.put<BbsObjectModel>(`${this.controllerUrl}/${payload.id}`, payload.bbsObject);
  }

  saveObjectCustomFields(payload: { id: number; items: CustomFieldModel[] }): Observable<CustomFieldModel[]> {
    return this.httpClient.put<CustomFieldModel[]>(`${this.controllerUrl}/${payload.id}/custom-fields`, payload.items);
  }

  findObjectCustomFields(payload: { id: number; includeSystemFields: boolean }): Observable<CustomFieldModel[]> {
    const queryParams: Params = {
      includeSystemFields: payload.includeSystemFields,
    };
    return this.httpClient.get<CustomFieldModel[]>(`${this.controllerUrl}/${payload.id}/custom-fields`, {params: queryParams});
  }

  isNameAvailable(payload: { name: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-name`, `${payload.name}`);
  }

  isTableNameAvailable(payload: { tableName: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-table-name`, `${payload.tableName}`);
  }
}
