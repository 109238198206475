import { Injectable } from '@angular/core';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { PermissionActionEnum } from '@base/modules/rest/permissions/enums/permission-action.enum';
import { PermissionVisibilityEnum } from '@base/modules/rest/permissions/enums/permission-visibility.enum';
import { SystemRoles } from '@base/modules/rest/user/enum/system-roles.enum';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import {
  hasPermission,
  hasPermissionWithVisibility,
  hasSystemRole
} from '@base/modules/security/utils/permissions.util';
import { UserContext } from '@base/services/user-context';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionsService {

  constructor(private userContext: UserContext) {
  }

  hasPermission(view: ObjectNameEnum, actions: PermissionActionEnum[]): boolean {
    return hasPermission(this.userContext.userContext, view, actions);
  }

  hasPermission$(view: ObjectNameEnum, actions: PermissionActionEnum[]): Observable<boolean> {
    return this.userContext.userContext$
      .pipe(
        map((userContext: CurrentUserContextResponseModel) => hasPermission(userContext, view, actions))
      );
  }

  hasPermissionWithVisibility$(view: ObjectNameEnum, action: PermissionActionEnum, visibility: PermissionVisibilityEnum): Observable<boolean> {
    return this.userContext.userContext$
      .pipe(
        map((userContext: CurrentUserContextResponseModel) => hasPermissionWithVisibility(userContext, view, action, visibility))
      );
  }

  hasSystemRole$(systemRoles: SystemRoles[]): Observable<boolean> {
    return this.userContext.userContext$
      .pipe(
        map((userContext: CurrentUserContextResponseModel) => hasSystemRole(userContext, systemRoles))
      );
  }
}
