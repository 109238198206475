import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { BuiSpinnerComponent } from '@shared/modules/bui/bui-spinner/bui-spinner.component';
import { BuiCardModule } from '../bui-card/bui-card.module';

@NgModule({
  declarations: [
    BuiSpinnerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    BuiCardModule,
    MatButtonModule,
  ],
  exports: [
    BuiSpinnerComponent,
  ],
})
export class BuiSpinnerModule {
}
