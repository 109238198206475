import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PermissionsService } from '@base/modules/security/services/permissions.service';
import { NO_PERMISSION_PAGE } from '@base/services/app-routes.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const SYSTEM_ROLES = 'system_roles';

@Injectable()
export class SystemRolesGuard implements CanActivate {

  constructor(private permissionsService: PermissionsService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.permissionsService.hasSystemRole$(route.data[SYSTEM_ROLES])
      .pipe(
        take(1),
        map((hasSystemRole: boolean) => {
          if (hasSystemRole) {
            return true;
          } else {
            return this.router.createUrlTree([NO_PERMISSION_PAGE]);
          }
        })
      );
  }
}
