import { Router } from '@angular/router';
import { HOME_PAGE } from '@base/services/app-routes.service';

export function redirectToUrl(payload: { router: Router; redirectUrl: string; addToken: boolean; accessToken: string; refreshToken: string }): void {
  if (payload.redirectUrl) {
    if (payload.addToken) {
      const url = prepareUrl({
        url: payload.redirectUrl,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      });
      window.location.replace(url);
    } else {
      payload.router.navigateByUrl(payload.redirectUrl);
    }
  } else {
    payload.router.navigate([HOME_PAGE]);
  }
}

function prepareUrl(payload: { url: string; accessToken: string; refreshToken: string }): string {
  let url = payload.url;
  url = addParamsToUrl(url, 'accessToken', payload.accessToken);
  return addParamsToUrl(url, 'refreshToken', payload.refreshToken);
}

function addParamsToUrl(url: string, key: string, value: string): string {
  if (!value) {
    return url;
  }
  if (!url.includes('?')) {
    url += '?';
  }
  if (!url.endsWith('?')) {
    url += '&';
  }
  return `${url}${key}=${value}`;
}
