export enum StateNameEnum {
  core_ui = 'core_ui',
  core_organization = 'core_organization',
  core_user = 'core_user',
  core_application = 'core_application',

  dialog_accountsImport = 'dialog_accountsImport',
  dialog_editUserPersonalInformation = 'dialog_editUserPersonalInformation',

  login_feature = 'login_feature',
  registration_feature = 'registration_feature',
  account_feature = 'account_feature',
  organization_feature = 'organization_feature',
  license_feature = '',
  reports_feature = 'reports_feature',

  leaveRequest_feature = 'leaveRequest_feature',
}
