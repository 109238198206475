import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  openInfoMessage(key: string): void {
    const message = key ? this.translate.instant(key) : '';
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar--bui-info',
    });
  }

  openSuccessMessage(key: string): void {
    const message = key ? this.translate.instant(key) : '';
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar--bui-success',
    });
  }

  openErrorMessage(key: string): void {
    const message = key ? this.translate.instant(key) : '';
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar--bui-error',
    });
  }
}
