import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { loadMessages, locale } from 'devextreme/localization';
import { getLanguage, setLanguage } from '../utils/language.util';

const devextreme_sr_Latn = require('assets/i18-devextreme/sr-Latn.json');
const devextreme_sr_Cyrl = require('assets/i18-devextreme/sr-Cyrl.json');
loadMessages(devextreme_sr_Latn);
loadMessages(devextreme_sr_Cyrl);

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  constructor(private translate: TranslateService) {
    const lang = getLanguage();
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  setLanguage(lang: string): void {
    if (lang) {
      this.setLanguageApp(lang);
      this.setLanguageDevextreme(lang);
      setLanguage(lang);
    }
  }

  private setLanguageApp(lang: string): void {
    this.translate.use(lang);
  }

  private setLanguageDevextreme(lang: string): void {
    switch (lang) {
      case 'lat':
        locale('sr-Latn');
        break;
      case 'cir':
        locale('sr-Cyrl');
        break;
      default:
        locale('en');
    }
  }

  getSelectedLanguage(): any {
    return getLanguage();
  }
}
