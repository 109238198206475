import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';

const MODULES: any = [
  CommonModule,
];

const COMPONENTS: any = [
  SvgIconComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    ...MODULES,
  ],
  exports: [
    ...COMPONENTS,
  ],
})
export class SvgIconModule {
}
