import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { PermissionsRestService } from '@base/modules/rest/permissions/permissions-rest.service';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { DESCRIPTION, NAME } from '@base/utils/table-data.util';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable({
  providedIn: 'root',
})
export class PermissionsRestAdapterService extends ObjectRestService{

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private permissionsRestService: PermissionsRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.Permission, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    const bbsObjectField = {
      label: 'view',
      dbName: 'bbsObject.description',
      translated: true,
    } as CustomFieldModel;
    return of({
      name: ObjectNameEnum.Permission,
      mainField: NAME,
      columns: [NAME, DESCRIPTION, 'bbsObject.description', 'action', 'visibility', 'visibilityDomain'],
      fields: [bbsObjectField],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.permissionsRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.permissionsRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.permissionsRestService.create({permission: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.permissionsRestService.update({id: payload.id, permission: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    return this.permissionsRestService.delete({ids: [payload.id]});
  }
}
