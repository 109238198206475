import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { UserGroupModel } from '@base/modules/rest/user-groups/model/user-group.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class UserGroupsRestService implements PageableRestService<UserGroupModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/user-groups';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  getAll(): Observable<UserGroupModel[]> {
    return this.httpClient.get<UserGroupModel[]>(`${this.controllerUrl}/all`);
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<UserGroupModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.httpClient.get<PaginationResponseModel<UserGroupModel>>(this.controllerUrl, { params: queryParams });
  }

  findByIds(payload: { ids: number[] }): Observable<UserGroupModel[]> {
    return this.httpClient.post<UserGroupModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<UserGroupModel> {
    return this.httpClient.get<UserGroupModel>(`${this.controllerUrl}/${payload.id}`);
  }

  create(payload: { userGroup: UserGroupModel }): Observable<UserGroupModel> {
    return this.httpClient.post<UserGroupModel>(this.controllerUrl, payload.userGroup);
  }

  update(payload: { id: number; userGroup: UserGroupModel }): Observable<UserGroupModel> {
    return this.httpClient.put<UserGroupModel>(`${this.controllerUrl}/${payload.id}`, payload.userGroup);
  }

  deleteBulk(payload: { ids: number[] }): Observable<void> {
    return this.httpClient.put<void>(`${this.controllerUrl}/delete-bulk`, payload.ids);
  }
}
