import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_LENGTH = 40;

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, length?: number): string {
    return this.truncate(value, length);
  }

  private truncate(str: string, num: number): string {
    if (!str) {
      return str;
    }

    const length = num || DEFAULT_LENGTH;

    if (str.length <= length) {
      return str;
    }

    return str.slice(0, length) + '...';
  }
}
