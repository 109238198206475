import { formatDate } from '@angular/common';

export function saveArrayBufferAsFile(result: ArrayBuffer, fileName: string): void {
  const url = URL.createObjectURL(new Blob([result]));
  saveToDisk(url, fileName);
}

export function saveBlobAsFile(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  saveToDisk(url, fileName);
}

export function saveStringAsFile(content: string, type: string, fileName: string): void {
  const blob = new Blob([content], {type});
  const url = window.URL.createObjectURL(blob);
  saveToDisk(url, fileName);
}

function saveToDisk(url: string, fileName: string): void {
  const file = document.createElement('a');
  file.href = url;
  file.download = fileName;
  file.click();
  URL.revokeObjectURL(url);
}

export function generateFileName(objectName: string, extension: string): string {
  return objectName + '_' + formatDate(new Date(), 'dd_MM_yyyy_HH_mm_ss', 'en_US') + extension;
}

export function openPDFInNewWindow(val: Blob): void {
  const pdfUrl = URL.createObjectURL(val);
  window.open(pdfUrl);
}
