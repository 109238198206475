import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconModule } from '@shared/components/svg-icon/svg-icon.module';
import { AngularMaterialModule } from '@shared/external-modules/angular-material.module';
import { BuiPlaceholderModule } from '@shared/modules/bui/bui-placeholder/bui-placeholder.module';
import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AngularMaterialModule,
    SvgIconModule,
    TranslateModule,
    BuiPlaceholderModule,
  ],
  declarations: [
    PageNotFoundComponent,
  ],
})
export class PageNotFoundModule { }
