import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { Observable } from 'rxjs';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { Params } from '@angular/router';
import { LayoutModel } from '@base/modules/rest/layout/model/layout.model';
import { ChangeActivityModel } from '@base/modules/rest/master-data/model/change-activity.model';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class LayoutRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/layouts';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  findAll(payload: { pagination: BeyondPaginationModel; bbsObjectId?: number }): Observable<PaginationResponseModel<LayoutModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter,
    };
    if (payload.bbsObjectId) {
      queryParams['bbsObjectId'] = payload.bbsObjectId;
    }
    return this.httpClient.get<PaginationResponseModel<LayoutModel>>(this.controllerUrl, {params: queryParams});
  }

  findOne(payload: { id: number }): Observable<LayoutModel> {
    return this.httpClient.get<LayoutModel>(`${this.controllerUrl}/${payload.id}`);
  }

  create(payload: { layout: LayoutModel }): Observable<LayoutModel> {
    return this.httpClient.post<LayoutModel>(`${this.controllerUrl}`, payload.layout);
  }

  update(payload: { id: number; layout: LayoutModel }): Observable<LayoutModel> {
    return this.httpClient.put<LayoutModel>(`${this.controllerUrl}/${payload.id}`, payload.layout);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.httpClient.delete<void>(`${this.controllerUrl}/${payload.id}`);
  }

  findByIds(payload: { ids: number[] }): Observable<LayoutModel[]> {
    return this.httpClient.post<LayoutModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  isNameAvailable(payload: { name: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-name`, `${payload.name}`);
  }

  changeActivity(payload: { data: ChangeActivityModel }): Observable<LayoutModel> {
    const id = payload.data.id;
    const data = {active: payload.data.active};
    return this.httpClient.post<any>(`${this.controllerUrl}/${id}/change-activity`, data);
  }
}
