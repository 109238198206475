export enum FilterOperationEnum {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  CONTAINS = 'CONTAINS',
  HAS_VALUE = 'HAS_VALUE',
  DOES_NOT_HAVE_VALUE = 'DOES_NOT_HAVE_VALUE',
  NOT_IN = 'NOT_IN',
  FROM = 'FROM',
  TO = 'TO',
  GREATER_THEN = 'GREATER_THEN',
  LESS_THEN = 'LESS_THEN',
  IN = 'IN',
}
