import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { McmWizardRestService } from '@base/modules/rest/mcm-wizard/mcm-wizard-rest.service';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { ObjectDefinitionModel } from '../bbs-object/model/object-definition.model';
import { AppConfiguration } from '../../../../app-configuration';
import { createField } from '../custom-field/model/custom-field.model';
import { CustomFieldTypesEnum } from '../custom-field/enums/custom-field-types.enum';

@Injectable({
  providedIn: 'root',
})
export class McmWizardRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private mcmWizardRestService: McmWizardRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.MCMWizard, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.MCMWizard,
      mainField: 'bbsObject.name',
      columns: ['bbsObject.name', 'visibilityDomain'],
      fields: [
        createField({label: 'Objekat', dbName: 'bbsObject.name', type: CustomFieldTypesEnum.TEXT, translated: true}),
        createField({
          label: 'Vidljivost',
          dbName: 'visibilityDomain',
          type: CustomFieldTypesEnum.TEXT,
          translated: true
        }),
      ],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.mcmWizardRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.mcmWizardRestService.getOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.mcmWizardRestService.create({mcmWizard: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.mcmWizardRestService.update({id: payload.id, mcmWizard: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    return this.mcmWizardRestService.delete({ids: [payload.id]});
  }
}
