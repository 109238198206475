import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ObjectAttachmentModel } from '@base/modules/rest/object-attachment/object-attachment.model';
import { FileUploadingModel } from '@base/modules/rest/common/model/file-uploading.model';
import { map } from 'rxjs/operators';
import { generateUUID } from '@base/utils/uuid';
import { AppConfiguration } from '../../../../app-configuration';
import { FileRestUtilsService } from '../common/file-rest-utils.service';

@Injectable()
export class ObjectAttachmentRestService {
  controllerUrl: string;

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration,
              private fileRestUtilsService: FileRestUtilsService) {
    this.controllerUrl = this.appConfiguration.serverApiUrl + '/secured/object-attachment';
  }

  uploadFile<T>(payload: {
    objectName: string;
    recordId: number;
    file: File;
    attachment?: ObjectAttachmentModel;
  }): Observable<FileUploadingModel<T>> {
    const data: FormData = new FormData();
    data.append('file', payload.file);
    data.append('objectAttachment', JSON.stringify(payload.attachment));
    const guid = generateUUID();
    return this.fileRestUtilsService.uploadFileRequest(`${this.controllerUrl}/${payload.objectName}/${payload.recordId}/upload`, data, payload.file.size)
      .pipe(
        map((res: FileUploadingModel<T>) => {
          res._guid = guid;
          return res;
        }),
      );
  }

  updateAttachment(payload: { attachment: ObjectAttachmentModel }): Observable<any> {
    return this.httpClient.put(`${this.controllerUrl}/${payload.attachment?.id}`, payload.attachment);
  }

  downloadAttachmentUrl(payload: { attachmentId: number }): string {
    return `${this.controllerUrl}/${payload.attachmentId}/download`;
  }
}
