import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PermissionsService } from '@base/modules/security/services/permissions.service';
import { NO_PERMISSION_PAGE } from '@base/services/app-routes.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const PERMISSION_VIEW = 'permission_view';
export const PERMISSION_ACTIONS = 'permission_actions';

@Injectable()
export class PermissionsGuard implements CanActivate {

  constructor(private permissionsService: PermissionsService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.permissionsService.hasPermission$(route.data[PERMISSION_VIEW], route.data[PERMISSION_ACTIONS])
      .pipe(
        take(1),
        map((hasPermission: boolean) => {
          if (hasPermission) {
            return true;
          } else {
            return this.router.createUrlTree([NO_PERMISSION_PAGE]);
          }
        })
      );
  }
}
