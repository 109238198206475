import { NgModule } from '@angular/core';
import { PermissionsGuard } from '@base/modules/security/guards/permissions-guard.service';
import { SystemRolesGuard } from '@base/modules/security/guards/system-roles-guard.service';
import { PermissionsService } from '@base/modules/security/services/permissions.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    PermissionsService,
    PermissionsGuard,
    SystemRolesGuard,
  ],
})
export class SecurityModule {
}
