import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { UserOrganizationApplicationModel } from '@base/modules/rest/user/model/user-organization-application.model';
import { UserFullResponseModel } from '@base/modules/rest/user/response/user-full-response.model';
import { UserContext } from '@base/services/user-context';
import { AppState } from '@base/store';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CoreApplicationActions } from '@base/store/application';

@Injectable({ providedIn: 'root' })
export class BeyondSuitePageGuard implements CanActivate {
  applications$: Observable<ApplicationModel[]> = this.userContext.applications$;
  _loading$: Subject<boolean> = new BehaviorSubject(false);

  constructor(private userContext: UserContext,
              private router: Router,
              private store: Store<AppState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (!!route.queryParams['forceHome']) {
      return true;
    }

    const user: UserFullResponseModel = this.userContext.user;
    this._loading$.next(true);

    const defaultApplicationId = this.findOrganizationDefaultApplicationId(user.userOrganizationApplications, this.userContext.activeOrganizationId);
    return this.applications$
      .pipe(
        take(1),
        map(applications =>
          applications
            .find(app => app.id === defaultApplicationId)
        ),
        map(app => {
          if (!app) {
            return true;
          }

          if (!app.internal) {
            this.store.dispatch(CoreApplicationActions.OpenBeyondSuite({url: app.url, clientId: app.name}));
            return false;
          }

          return this.router.createUrlTree([app.url]);
        }),
        tap(() => this._loading$.next(false))
      );
  }

  get loading$(): Observable<boolean> {
    return this._loading$;
  }

  private findOrganizationDefaultApplicationId(userOrganizationApplications: UserOrganizationApplicationModel[], organizationId: number): number {
    const defaultApplication = userOrganizationApplications
      .find(item => item.organizationId === organizationId && item.defaultApplication);
    return defaultApplication?.applicationId;
  }
}
