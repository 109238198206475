import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { CorePreloadActions } from '@base/store/preload';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export interface State {
  currentUserContext: CurrentUserContextResponseModel;
}

export const initialState: State = {
  currentUserContext: null,
};

export const reducer: ActionReducer<State, Action> = createReducer(
  initialState,
  on(CorePreloadActions.LoadAppSecuredDataSuccess, (state: State, {currentUserContextResponse}): State => ({
    ...state,
    currentUserContext: {...currentUserContextResponse},
  })),
  on(fromActions.ReloadUserContextSuccess, (state, {userContext}): State => ({
    ...state,
    currentUserContext: userContext,
  })),
  on(fromActions.SetUserContext, (state, {userContext}): State => ({
    ...state,
    currentUserContext: {
      ...state.currentUserContext,
      user: userContext,
    },
  })),
  on(fromActions.SetUserPhoto, (state, {userId, photoUrl}): State => ({
    ...state,
    currentUserContext: userId === state.currentUserContext.user.id ?
      {
        ...state.currentUserContext,
        user: {
          ...state.currentUserContext.user,
          photoHref: photoUrl,
        },
      } :
      state.currentUserContext,
  })),
  on(fromActions.UpdateUserOrganizationDefaultApplicationSuccess, (state, { user }): State => ({
    ...state,
    currentUserContext: {
      ...state.currentUserContext,
      user: user,
    },
  })),
  on(fromActions.SwitchActiveOrganizationSuccess, (state, { currentUserContextResponse }): State => ({
    ...state,
    currentUserContext: currentUserContextResponse,
  })),
  on(fromActions.LogoutSuccess, (): State => initialState)
);
