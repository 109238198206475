import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import { createAction, props } from '@ngrx/store';
import { PoslovnaGodinaModel } from '@base/modules/rest/mis4/P/poslovna-godina.model';

export const OrganizationRegistrationConfirmed = createAction(
  '[Core - organization] Organization registration confirmed'
);

export const SetOrganization = createAction(
  '[Core - organization] Set organization',
  props<{ organization: OrganizationMasterDataModel }>()
);

export const SetPoslovnaGodina = createAction(
  '[Core - organization] Set poslovna godina',
  props<{ poslovnaGodina: PoslovnaGodinaModel }>()
);
