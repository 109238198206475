import { Overlay } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@base/core.module';
import { AppState } from '@base/store';
import { CorePreloadActions } from '@base/store/preload';
import { CoreUiSelectors } from '@base/store/ui';
import { ThemeModule } from '@modules/theme/theme.module';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AngularMaterialModule,
  MAT_DIALOG_DEFAULT_OPTIONS_PROVIDER,
  MAT_SNACK_BAR_DEFAULT_OPTIONS_PROVIDER
} from '@shared/external-modules/angular-material.module';
import { BuiSpinnerModule } from '@shared/modules/bui/bui-spinner/bui-spinner.module';
import { filter, take } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { DEFAULT_LOCALE_ID } from '@shared/util/util';
import localeSrLatn from '@angular/common/locales/sr-Latn';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeSrLatn);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BuiSpinnerModule,
    CoreModule,
    HttpClientModule,
    MatIconModule,
    ThemeModule,
    HighlightModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => {
        return () => new Promise(resolve => {

          // Dispatch loading action
          store.dispatch(CorePreloadActions.LoadAppPublicData());

          // Wait until loading finish
          store.select(CoreUiSelectors.loadingData)
            .pipe(filter((loadingData: boolean) => !loadingData))
            .pipe(take(1))
            .subscribe(() => {
              resolve(true);
            });
        });
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: LOCALE_ID,
      useValue: DEFAULT_LOCALE_ID,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    MAT_DIALOG_DEFAULT_OPTIONS_PROVIDER,
    MAT_SNACK_BAR_DEFAULT_OPTIONS_PROVIDER,
    MatSnackBar,
    Overlay,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconSetInNamespace(
      'mdi',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/mdi.svg')
    );
    iconRegistry.addSvgIconSetInNamespace(
      'mat',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/mat.svg')
    );
  }
}
