import { ActivatedRoute, Router } from '@angular/router';

export const reloadContext = (router: Router, route: ActivatedRoute) => {
  const currentRoute = router.url.split('?')[0];
  router.routeReuseStrategy.shouldReuseRoute = () => false;
  router.onSameUrlNavigation = 'reload';
  router.navigate([currentRoute],
    {relativeTo: route, queryParamsHandling: 'preserve'}).then();
};

