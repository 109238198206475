import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploadingModel } from '@base/modules/rest/common/model/file-uploading.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type ResponseType = 'blob' | 'json' | 'text' | 'arraybuffer';

@Injectable()
export class FileRestUtilsService {

  constructor(private httpClient: HttpClient) {
  }

  uploadFileRequest<T>(url: string, formData: FormData, fileSize: number, responseType: ResponseType = 'text'): Observable<FileUploadingModel<T>> {
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: responseType,
      headers: new HttpHeaders({'file_uploading': 'true'}),
    });

    return this.httpClient.request(req)
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.Response) {
            const body = event.body;
            let responseData: T;
            if (body) {
              if (responseType !== 'json') {
                responseData = body as any;
              } else {
                responseData = JSON.parse(body as string);
              }
            }
            return new FileUploadingModel(true, fileSize, fileSize, responseData);
          } else if (event.type === HttpEventType.UploadProgress) {
            return new FileUploadingModel<T>(false, fileSize, event.loaded);
          } else {
            return new FileUploadingModel(false, fileSize, 0);
          }
        }),
      );
  }
}
