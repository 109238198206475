import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { CustomFieldRestService } from '@base/modules/rest/custom-field/custom-field-rest.service';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class CustomFieldRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private customFieldRestService: CustomFieldRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.CustomField, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.CustomField,
      mainField: 'label',
      columns: ['label', 'type'],
      fields: [],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.customFieldRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.customFieldRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.customFieldRestService.create({customField: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.customFieldRestService.update({customField: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    return this.customFieldRestService.delete(payload);
  }

  findAll(): Observable<any[]> {
    throw new Error('Not implemented method');
  }
}
