export enum PermissionActionEnum {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  COPY = 'COPY',
  POST = 'POST',
  EXPORT = 'EXPORT',
  FULL = 'FULL',
  SAVE = 'SAVE',
  DISCOUNT = 'DISCOUNT',
  PRICE_CHANGE = 'PRICE_CHANGE',
  PRICE_CHANGE_SERVICE = 'PRICE_CHANGE_SERVICE'
}
