import { InjectionToken } from '@angular/core';
import { CoreApplicationReducer } from '@base/store/application';
import { CoreOrganizationReducer } from '@base/store/organization';
import { CorePreloadEffects } from '@base/store/preload/effects';
import { StateNameEnum } from '@base/store/state-name.enum';
import { CoreUiReducer } from '@base/store/ui';
import { CoreUserReducer } from '@base/store/user';
import { CoreUserEffects } from '@base/store/user/effects';
import { Action, ActionReducerMap } from '@ngrx/store';
import { CoreApplicationEffects } from './application/effects';
import { CoreOrganizationEffects } from '@base/store/organization/effects';

export interface AppState {
  [StateNameEnum.core_ui]: CoreUiReducer.State;
  [StateNameEnum.core_organization]: CoreOrganizationReducer.State;
  [StateNameEnum.core_user]: CoreUserReducer.State;
  [StateNameEnum.core_application]: CoreApplicationReducer.State;
}

export const ROOT_REDUCERS: InjectionToken<ActionReducerMap<AppState, Action>> = new InjectionToken(
  'ROOT_REDUCERS_TOKEN',
  {
    factory: () => ({
      [StateNameEnum.core_ui]: CoreUiReducer.reducer,
      [StateNameEnum.core_organization]: CoreOrganizationReducer.reducer,
      [StateNameEnum.core_user]: CoreUserReducer.reducer,
      [StateNameEnum.core_application]: CoreApplicationReducer.reducer,
    }),
  }
);

export const ROOT_EFFECTS = [
  CorePreloadEffects,
  CoreUserEffects,
  CoreApplicationEffects,
  CoreOrganizationEffects,
];
