import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginToken } from '@views/public/login/model/login-token.model';
import { AppConfiguration } from 'app/app-configuration';
import { DeviceInfo } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable()
export class BeyondSuiteRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/beyond-suite';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  openBeyondSuite(payload: { clientId: string; deviceInfo: DeviceInfo }): Observable<LoginToken> {
    const requestData = {
      clientId: payload.clientId,
      deviceInfoDto: {
        browser: payload.deviceInfo.browser,
        browserVersion: payload.deviceInfo.browser_version,
        device: payload.deviceInfo.device,
        os: payload.deviceInfo.os,
        osVersion: payload.deviceInfo.os_version,
      },
    };
    return this.httpClient.post<LoginToken>(this.controllerUrl + '/open', requestData);
  }

  logoutManufacturing(): Observable<void> {
    return this.httpClient.get<void>(this.controllerUrl + '/logout');
  }
}
