import { Injectable } from '@angular/core';
import { ApplicationNameEnum } from '../enums/application-name.enum';

// Public views
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const PASSWORD_RESET_PAGE = '/password/reset';

// Secured views
export const HOME_PAGE = '/home';
export const NO_PERMISSION_PAGE = '/no-permission';
export const NOT_FOUND_PAGE = '/not-found';

// SMART REPORTS
export const SMART_REPORTS_PAGE = `/${ApplicationNameEnum.BEYOND_SMART_REPORTS}`;
export const SMART_REPORTS_VIEW_PAGE = (id: number) => `${SMART_REPORTS_PAGE}/${id}`;
export const SMART_REPORTS_EDIT_PAGE = (id) => `${SMART_REPORTS_VIEW_PAGE(id)}/edit`;
export const SMART_REPORTS_COPY_PAGE = (id) => `${SMART_REPORTS_VIEW_PAGE(id)}/copy`;
export const SMART_REPORTS_ADD_PAGE = `${SMART_REPORTS_PAGE}/add`;

// CORE
export const BEYOND_CORE_ROOT = `/${ApplicationNameEnum.BEYOND_CORE}`;
export const CORE_SETTINGS_ROOT = `${BEYOND_CORE_ROOT}/settings`;

// MTM
export const BEYOND_MOBILE_TIME_MANAGEMENT_ROOT = `/${ApplicationNameEnum.BEYOND_MOBILE_TIME_MANAGEMENT}`;
export const BEYOND_MOBILE_TIME_MANAGEMENT_SETTINGS_ROOT_PAGE = `${BEYOND_MOBILE_TIME_MANAGEMENT_ROOT}/settings`;
export const BEYOND_MOBILE_TIME_MANAGEMENT_ABOUT_PAGE = `${BEYOND_MOBILE_TIME_MANAGEMENT_SETTINGS_ROOT_PAGE}/about`;
export const LEAVE_REQUESTS_ROOT_PAGE = `${BEYOND_MOBILE_TIME_MANAGEMENT_ROOT}/leave-requests`;
export const LEAVE_REQUEST_ADD_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/add`;
export const LEAVE_REQUESTS_EMPLOYEES_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/employees`;
export const LEAVE_REQUESTS_PERSONAL_PAGE = `${LEAVE_REQUESTS_ROOT_PAGE}/personal`;
export const LEAVE_REQUESTS_FILTER_PAGE = `${LEAVE_REQUESTS_EMPLOYEES_PAGE}/filter`;

@Injectable()
export class AppRoutesService {
}
