import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicPagesCanActivateGuard } from '@shared/guards/public-pages-can-activate.guard';
import { SecuredPagesCanActivateGuard } from '@shared/guards/secured-pages-can-activate.guard';
import { PageNotFoundComponent } from '@views/page-not-found/page-not-found.component';
import { PageNotFoundModule } from '@views/page-not-found/page-not-found.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/public/public.module')
      .then(m => m.PublicModule),
    canActivate: [PublicPagesCanActivateGuard],
  },
  {
    path: '',
    loadChildren: () => import('./views/secured/secured.module')
      .then(m => m.SecuredModule),
    canActivate: [SecuredPagesCanActivateGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        useHash: true,
      }
    ),
    PageNotFoundModule,
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
