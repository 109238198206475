import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformListToHumanReadableText',
})
export class TransformListToHumanReadableTextPipe implements PipeTransform {

    transform(listValues: any): any {
      if (listValues == null) {
        return '';
      }
      let text = '';
      for (let i = 0; i < listValues.length - 1; i++) {
        text += this.transformToHumanReadableWord(listValues[i]) + ', ';
      }
      return text + this.transformToHumanReadableWord(listValues[listValues.length - 1]);
    }

    private transformToHumanReadableWord(value: string): string {
      const words = value.split('_');
      words.map((word: string, index: number) => {
        words[index] = word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      });
      return words.join(' ');
    }
}
