import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCssClassName',
})
export class FormatCssClassNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value
      .trim()
      .replace(/ /g, '-')
      .replace(/_/g, '-')
      .toLowerCase();
  }
}
