import { Component, OnInit } from '@angular/core';
import { ActuatorRestService } from '@base/modules/rest/actuator/actuator-rest.service';
import { TranslationService } from '@base/services/translation.service';
import { AppState } from '@base/store';
import { CoreUiSelectors } from '@base/store/ui';
import { Store } from '@ngrx/store';
import { BeyondSuitePageGuard } from '@views/secured/beyond-suite/guard/beyond-suite-page.guard';
import { Observable } from 'rxjs';
import { CoreApplicationSelectors } from '@base/store/application';
import { ERROR_STATE_MATCHER_PROVIDER } from '@shared/external-modules/angular-material.module';
import { setAppVersion } from './version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ERROR_STATE_MATCHER_PROVIDER,
  ],
})
export class AppComponent implements OnInit {
  loadingData$: Observable<boolean> = this.store.select(CoreUiSelectors.loadingData);
  homePageLoading$: Observable<boolean> = this.beyondSuitePageGuard.loading$;
  isTouchDevice: boolean;

  constructor(private store: Store<AppState>,
              private translationService: TranslationService,
              private actuatorRestService: ActuatorRestService,
              private beyondSuitePageGuard: BeyondSuitePageGuard) {
  }

  ngOnInit(): void {
    this.store.select(CoreApplicationSelectors.info)
      .subscribe(info => setAppVersion(info?.version));

    // Detect touch device.
    this.isTouchDevice = 'ontouchstart' in window;

    // Set helper class to `<html>` element to use if is touch device.
    if (this.isTouchDevice) {
      const rootElement = document.documentElement;
      rootElement.classList.add('is-touch-device');
    }
  }
}
