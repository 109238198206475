import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '@shared/components/svg-icon/svg-icon.module';
import { BuiPlaceholderComponent } from '@shared/modules/bui/bui-placeholder/bui-placeholder.component';

@NgModule({
  declarations: [
    BuiPlaceholderComponent,
  ],
  exports: [
    BuiPlaceholderComponent,
  ],
  imports: [
    CommonModule,
    SvgIconModule,
    RouterModule,
  ],
})
export class BuiPlaceholderModule {
}
