import { Injectable } from '@angular/core';
import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { MenuItemModel } from '@base/modules/rest/application/model/menu-item.model';
import { MenuModel } from '@base/modules/rest/application/model/menu.model';
import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import { EmployeeModel } from '@base/modules/rest/user/model/employee.model';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { UserFullResponseModel } from '@base/modules/rest/user/response/user-full-response.model';
import { hasPermission } from '@base/modules/security/utils/permissions.util';
import { AppState } from '@base/store';
import { CoreApplicationSelectors } from '@base/store/application';
import { CoreOrganizationSelectors } from '@base/store/organization';
import { CoreUserSelectors } from '@base/store/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemRoles } from '../modules/rest/user/enum/system-roles.enum';
import { StrategijskaPoslovnaJedinicaModel } from '../modules/rest/mis4/S/strategijska-poslovna-jedinica.model';
import { OrganizacijaModel } from '../modules/rest/mis4/O/organizacija.model';
import { PoslovnaGodinaModel } from '../modules/rest/mis4/P/poslovna-godina.model';
import { OrganizacionaJedinicaModel } from '../modules/rest/mis4/O/organizaciona-jedinica.model';

const ACTIVE_PROFILE_KEY = 'active_profile';
const DEFAULT_PROFILE = 'BAPI';

@UntilDestroy()
@Injectable()
export class UserContext {
  private _userContext$ = new BehaviorSubject<CurrentUserContextResponseModel>(undefined);
  private _spj$ = new BehaviorSubject<StrategijskaPoslovnaJedinicaModel>(undefined);
  private _activnaOrganizacija$ = new BehaviorSubject<OrganizacijaModel>(undefined);
  private _activeOrganization$ = new BehaviorSubject<OrganizationMasterDataModel>(undefined);
  private _aktivnaPoslovnaGodina$ = new BehaviorSubject<PoslovnaGodinaModel>(undefined);

  constructor(private store: Store<AppState>) {
    this.store.select(CoreUserSelectors.currentUserContext)
      .pipe(untilDestroyed(this))
      .subscribe((val: CurrentUserContextResponseModel) => this._userContext$.next(val));
    this.store.select(CoreOrganizationSelectors.aktivnaOrganizacija)
      .pipe(untilDestroyed(this))
      .subscribe((val: OrganizacijaModel) => this._activnaOrganizacija$.next(val));
    this.activeOrganization$
      .pipe(untilDestroyed(this))
      .subscribe(val => this._activeOrganization$.next(val));
    this.store.select(CoreOrganizationSelectors.spj)
      .pipe(untilDestroyed(this))
      .subscribe((val: StrategijskaPoslovnaJedinicaModel) => this._spj$.next(val));
    this.store.select(CoreOrganizationSelectors.poslovnaGodina)
      .pipe(untilDestroyed(this))
      .subscribe((val: PoslovnaGodinaModel) => this._aktivnaPoslovnaGodina$.next(val));
  }

  get userContext$(): Observable<CurrentUserContextResponseModel> {
    return this._userContext$.asObservable();
  }

  get userContext(): CurrentUserContextResponseModel {
    return this._userContext$.value;
  }

  get user$(): Observable<UserFullResponseModel> {
    return this._userContext$.asObservable()
      .pipe(map(val => val?.user));
  }

  get user(): UserFullResponseModel {
    return this._userContext$.value?.user;
  }

  get userId(): number {
    return this.user?.id;
  }

  get username(): string {
    return this.user?.username;
  }

  get defaultOrganizationId(): number {
    return this.user?.defaultOrganizationId;
  }

  get isOwnerOrAdmin(): boolean {
    return this.user?.systemRole === SystemRoles.OWNER || this.user?.systemRole === SystemRoles.ADMINISTRATOR;
  }

  get organizations$(): Observable<OrganizationMasterDataModel[]> {
    const organizations$ = this.store.select(CoreOrganizationSelectors.organizations);
    return combineLatest([organizations$, this.user$])
      .pipe(
        map(([organizations, user]) => {
          return user.organizations
            .map(userOrganization => organizations.find(org => org.id === userOrganization.id))
            .filter(org => !!org);
        })
      );
  }

  get applications$(): Observable<ApplicationModel[]> {
    const applications$ = this.store.select(CoreApplicationSelectors.applications);
    return combineLatest([applications$, this.userContext$])
      .pipe(
        map(([applications, userContext]: [ApplicationModel[], CurrentUserContextResponseModel]) => {
          return applications
            .filter(app => userContext?.applications.some(userApp => userApp.id === app.id));
        })
      );
  }

  get activeOrganizationId$(): Observable<number> {
    return this.userContext$
      .pipe(map(userContext => userContext?.activeOrganizationId));
  }

  get activeOrganizationId(): number {
    return this.userContext.activeOrganizationId;
  }

  get activeOrganization$(): Observable<OrganizationMasterDataModel> {
    const organizations$ = this.store.select(CoreOrganizationSelectors.organizations);
    return combineLatest([this.activeOrganizationId$, organizations$])
      .pipe(
        map(([organizationId, organizations]: [number, OrganizationMasterDataModel[]]) =>
          organizations.find(org => org.id === organizationId)
        )
      );
  }

  get activeOrganization(): OrganizationMasterDataModel {
    return this._activeOrganization$.value;
  }

  get spj(): StrategijskaPoslovnaJedinicaModel {
    return this._spj$.value;
  }

  get aktivnaPoslovnaGodina(): PoslovnaGodinaModel {
    return this._aktivnaPoslovnaGodina$.value;
  }

  get aktivnaOrganizacija(): OrganizacijaModel {
    return this._activnaOrganizacija$.value;
  }

  get organizacionaJedinica(): OrganizacionaJedinicaModel {
    return this._userContext$.value?.organizacionaJedinica;
  }

  menu$(applicationId: number): Observable<MenuModel> {
    return this.applications$
      .pipe(
        map(apps => apps.find(app => app.id === applicationId)),
        map(app => app.menu),
        map(menu => ({
          ...menu,
          menuItems: this.filterMenuItems(menu.menuItems),
        }))
      );
  }

  private filterMenuItems(menuItems: MenuItemModel[]): MenuItemModel[] {
    return menuItems
      .filter(item => this.hasPermissionForMenuItem(item))
      .map(item => ({
        ...item,
        menuItems: this.filterMenuItems(item.menuItems),
      }));
  }

  private hasPermissionForMenuItem(menuItem: MenuItemModel): boolean {
    const _hasRole = !menuItem.onlyVisibleTo || menuItem.onlyVisibleTo.includes(this.userContext.user.systemRole);
    const _hasPermission = !menuItem.permission || hasPermission(this.userContext, menuItem.permission.view, menuItem.permission.actions);
    return _hasRole || _hasPermission;
  }

  get employeeInfo$(): Observable<EmployeeModel> {
    return this.userContext$
      .pipe(map(userContext => userContext.employeeInfo));
  }

  get employeeInfo(): EmployeeModel {
    return this.userContext.employeeInfo;
  }

  get currency$(): Observable<string> {
    return this.activeOrganization$
      .pipe(map(org => org?.organizationReferences?.place?.country?.currency?.designation));
  }

  get ldapActive$(): Observable<boolean> {
    return this.store.select(CoreApplicationSelectors.info)
      .pipe(
        map(info => info?.ldapActive)
      );
  }

  get activeProfile(): string {
    const profile: string = localStorage.getItem(ACTIVE_PROFILE_KEY);
    if (!profile) {
      localStorage.setItem(ACTIVE_PROFILE_KEY, DEFAULT_PROFILE);
    }
    return localStorage.getItem(ACTIVE_PROFILE_KEY);
  }
}
