import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToHumanReadableText',
})
export class CamelCaseToHumanReadableTextPipe implements PipeTransform {

  transform(value: string): any {
    if (value == null) {
      return '';
    }
    const words = value.match(/[A-Za-z][a-z]*/g) || [];
    return this.firstCharToUpperCase(words.map(this.firstCharToLowerCase).join(' '));
  }

  firstCharToLowerCase(word: string): string {
    return word.charAt(0).toLowerCase() + word.substring(1);
  }

  firstCharToUpperCase(sentence: string): string {
    return sentence.charAt(0).toUpperCase() + sentence.substring(1);
  }
}
