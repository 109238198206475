import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DATETIME_FORMAT } from '@base/utils/date.util';

@Pipe({
    name: 'formatDateAndTime',
})
export class FormatDateTimePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: string): any {
      return new DatePipe(this.localeId).transform(value, DATETIME_FORMAT);
    }
}
