import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import {
  OrganizationRegisteredResponseModel
} from '@base/modules/rest/registration/response/organization-registered-response.model';
import { CorePreloadActions } from '@base/store/preload';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { CoreUserActions } from '../user';
import { StrategijskaPoslovnaJedinicaModel } from '../../modules/rest/mis4/S/strategijska-poslovna-jedinica.model';
import { OrganizacijaModel } from '../../modules/rest/mis4/O/organizacija.model';
import { PoslovnaGodinaModel } from '../../modules/rest/mis4/P/poslovna-godina.model';
import * as fromActions from './actions';

export interface State {
  organizations: OrganizationMasterDataModel[];
  organizationRegisteredResponse: OrganizationRegisteredResponseModel;
  hasActiveLicense: boolean;
  spj: StrategijskaPoslovnaJedinicaModel;
  poslovnaGodina: PoslovnaGodinaModel;
  aktivnaOrganizacija: OrganizacijaModel;
}

export const initialState: State = {
  organizations: [],
  organizationRegisteredResponse: {
    organizationRegistered: true,
    organizationRegistrationConfirmed: true,
  },
  hasActiveLicense: null,
  spj: null,
  poslovnaGodina: null,
  aktivnaOrganizacija: null,
};

export const reducer: ActionReducer<State, Action> = createReducer(
  initialState,
  on(CorePreloadActions.LoadAppPublicDataSuccess, (state: State, { organizationRegisteredResponse }): State => ({
    ...state,
    organizationRegisteredResponse: organizationRegisteredResponse,
  })),
  on(CorePreloadActions.LoadAppSecuredDataSuccess, (state: State, {
    organizations,
    hasActiveLicense,
    aktivnaOrganizacija,
    spj,
    poslovnaGodina,
  }): State => ({
    ...state,
    organizations: organizations,
    hasActiveLicense: hasActiveLicense,
    aktivnaOrganizacija: aktivnaOrganizacija,
    spj: spj,
    poslovnaGodina: poslovnaGodina,
  })),
  on(fromActions.OrganizationRegistrationConfirmed, (state: State): State => ({
    ...state,
    organizationRegisteredResponse: {
      ...state.organizationRegisteredResponse,
      organizationRegistrationConfirmed: true,
    },
  })),
  on(fromActions.SetOrganization, (state: State, { organization }): State => {
    const index = state.organizations.findIndex(org => org.id === organization.id);
    if (index === -1) {
      return {
        ...state,
        organizations: [
          ...state.organizations,
          organization,
        ],
      };
    } else {
      return {
        ...state,
        organizations: [
          ...state.organizations.slice(0, index),
          organization,
          ...state.organizations.slice(index + 1),
        ],
      };
    }
  }),
  on(fromActions.SetPoslovnaGodina, (state: State, { poslovnaGodina }): State => {
    return {
      ...state,
      poslovnaGodina: poslovnaGodina,
    };
  }),
  on(CoreUserActions.LoadActiveOrganizacijaSuccess, (state: State, {organizacija}): State => ({
    ...state,
    aktivnaOrganizacija: organizacija,
  }))
);
