export class FileUploadingModel<T> {
  uploaded: boolean;
  totalSize: number;
  uploadedSize: number;
  data: T;
  _guid?: string;

  constructor(uploaded: boolean, totalSize: number, uploadedSize: number, data?: T) {
    this.uploaded = uploaded;
    this.totalSize = totalSize;
    this.uploadedSize = uploadedSize;
    this.data = data;
  }
}
