<div class="c-bui-placeholder"
  [ngClass]="[
    hasTop ? 'c-bui-placeholder-top' : '',
    hasBottom ? 'c-bui-placeholder-bottom' : ''
  ]">
  <div class="c-bui-placeholder__image">
    <app-svg-icon [icon]="iconName"></app-svg-icon>
  </div>
  <div class="c-bui-placeholder__text">
    <h1 class="c-bui-placeholder__title">{{title}}</h1>
    <p class="c-bui-placeholder__description">{{description}}</p>
    <div class="c-bui-placeholder__link">
      <ng-content></ng-content>
    </div>
  </div>
</div>
