import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformToHumanReadableText',
})
export class TransformToHumanReadableTextPipe implements PipeTransform {

    transform(value: any): any {
      if (value == null) {
        return '';
      }
      const words = value.split('_');
      words.map((word: string, index: number) => {
        words[index] = word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      });
      return words.join(' ');
    }
}
