import { CorePreloadActions } from '@base/store/preload';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

export interface State {
  loadingData: boolean;
}

export const initialState: State = {
  loadingData: true,
};

export const reducer: ActionReducer<State, Action> = createReducer(
  initialState,
  on(CorePreloadActions.LoadAppPublicData, CorePreloadActions.LoadAppSecuredData, (state: State) => ({
    ...state,
    loadingData: true,
  })),
  on(CorePreloadActions.LoadAppPublicDataSuccess, CorePreloadActions.LoadAppSecuredDataSuccess, (state: State) => ({
    ...state,
    loadingData: false,
  }))
);
