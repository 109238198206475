import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DATE_FORMAT } from '@base/utils/date.util';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: any): any {
      return new DatePipe(this.localeId).transform(value, DATE_FORMAT);
    }
}
