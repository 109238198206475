/**
 * Determines how much information can the user access (i.e. view, edit, etc.).
**/
export enum SystemRoles {
  /** The owner has full access to the application. */
  OWNER = 'OWNER',

  /** An administrator can access more than a common user. */
  ADMINISTRATOR = 'ADMINISTRATOR',

  /** A common user has limited access. */
  USER = 'USER',
}
