const ApplicationNameEnum = {
  BEYOND_ACCOUNTING: 'BEYOND_ACCOUNTING' as 'BEYOND_ACCOUNTING',
  BEYOND_ASSETS: 'BEYOND_ASSETS' as 'BEYOND_ASSETS',
  BEYOND_BACK_OFFICE: 'BEYOND_BACK_OFFICE' as 'BEYOND_BACK_OFFICE',
  BEYOND_BUSINESS_SUITE: 'BEYOND_BUSINESS_SUITE' as 'BEYOND_BUSINESS_SUITE',
  BEYOND_COMPLAINT: 'BEYOND_COMPLAINT' as 'BEYOND_COMPLAINT',
  BEYOND_CORE: 'BEYOND_CORE' as 'BEYOND_CORE',
  BEYOND_FINANCE: 'BEYOND_FINANCE' as 'BEYOND_FINANCE',
  BEYOND_GOVERNMENT: 'BEYOND_GOVERNMENT' as 'BEYOND_GOVERNMENT',
  BEYOND_IDENTITY: 'BEYOND_IDENTITY' as 'BEYOND_IDENTITY',
  BEYOND_INVENTORY: 'BEYOND_INVENTORY' as 'BEYOND_INVENTORY',
  BEYOND_LOGISTIC: 'BEYOND_LOGISTIC' as 'BEYOND_LOGISTIC',
  BEYOND_LOYALTY: 'BEYOND_LOYALTY' as 'BEYOND_LOYALTY',
  BEYOND_MANUFACTURING: 'BEYOND_MANUFACTURING' as 'BEYOND_MANUFACTURING',
  BEYOND_MOBILE_TIME_MANAGEMENT: 'BEYOND_MOBILE_TIME_MANAGEMENT' as 'BEYOND_MOBILE_TIME_MANAGEMENT',
  BEYOND_MONITORING: 'BEYOND_MONITORING' as 'BEYOND_MONITORING',
  BEYOND_PARTNERS: 'BEYOND_PARTNERS' as 'BEYOND_PARTNERS',
  BEYOND_PAYROLL: 'BEYOND_PAYROLL' as 'BEYOND_PAYROLL',
  BEYOND_PEOPLE: 'BEYOND_PEOPLE' as 'BEYOND_PEOPLE',
  BEYOND_PERFORMANCE: 'BEYOND_PERFORMANCE' as 'BEYOND_PERFORMANCE',
  BEYOND_PLAN: 'BEYOND_PLAN' as 'BEYOND_PLAN',
  BEYOND_POS: 'BEYOND_POS' as 'BEYOND_POS',
  BEYOND_PROCUREMENT: 'BEYOND_PROCUREMENT' as 'BEYOND_PROCUREMENT',
  BEYOND_QUALITY_CONTROL: 'BEYOND_QUALITY_CONTROL' as 'BEYOND_QUALITY_CONTROL',
  BEYOND_RETAIL: 'BEYOND_RETAIL' as 'BEYOND_RETAIL',
  BEYOND_SMART_REPORTS: 'BEYOND_SMART_REPORTS' as 'BEYOND_SMART_REPORTS',
  BEYOND_WARRANTY: 'BEYOND_WARRANTY' as 'BEYOND_WARRANTY',
};
type ApplicationNameEnum = (typeof ApplicationNameEnum)[keyof typeof ApplicationNameEnum];
export { ApplicationNameEnum };

