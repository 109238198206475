import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerTypeEnum } from '@views/secured/beyond-retail/modules/rest/customer/enums/customer-type.enum';

@Pipe({
  name: 'customerType',
})
export class CustomerTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(type: CustomerTypeEnum): string {
    if (type === CustomerTypeEnum.BUSINESS) {
      return this.translateService.instant('CUSTOMERS.TYPE_OPTIONS.BUSINESS');
    } else {
      return this.translateService.instant('CUSTOMERS.TYPE_OPTIONS.INDIVIDUAL');
    }
  }
}
