import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { UserPhotoModel } from '@base/model/user/user-photo.model';
import { FileRestUtilsService } from '../common/file-rest-utils.service';
import { FileUploadingModel } from '@base/modules/rest/common/model/file-uploading.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { UserOrganizationApplicationModel } from '@base/modules/rest/user/model/user-organization-application.model';
import {
  UpdateUserOrganizationDefaultApplicationRequestModel
} from '@base/modules/rest/user/request/update-user-organization-default-application-request.model';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import {
  UserApplicationLoginActivityResponseModel
} from '@base/modules/rest/user/response/user-application-login-activity-response.model';
import { UserFullResponseModel } from '@base/modules/rest/user/response/user-full-response.model';
import { UserGroupSimpleModel } from '@base/modules/rest/user/response/user-group-simple.model';
import { UserResponseModel } from '@base/modules/rest/user/response/user-response.model';
import {
  ChangePasswordDto
} from '@views/secured/beyond-identity/account-management/account/modules/model/change-password-dto';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';
import { ApplicationNameEnum } from '../../../enums/application-name.enum';
import { LoggedInResponseModel } from './response/logged-in-response.model';

@Injectable()
export class UserRestService implements PageableRestService<UserResponseModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/users';

  constructor(private httpClient: HttpClient,
              private fileRestUtilsService: FileRestUtilsService,
              private appConfiguration: AppConfiguration) {
  }

  changePassword(changePasswordDto: ChangePasswordDto): Observable<UserResponseModel> {
    return this.httpClient.post<UserResponseModel>(this.controllerUrl + '/change-password', changePasswordDto);
  }

  isUserLoggedIn(): Observable<LoggedInResponseModel> {
    return this.httpClient.get<LoggedInResponseModel>(this.controllerUrl + '/is-logged-in');
  }

  currentUserContext(): Observable<CurrentUserContextResponseModel> {
    return this.httpClient.get<CurrentUserContextResponseModel>(this.controllerUrl + '/current-user');
  }

  findAll(payload: { pagination: BeyondPaginationModel; includeInvited?: boolean; applications?: ApplicationNameEnum[]; includeInactive?: boolean }): Observable<PaginationResponseModel<UserResponseModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
      includeInvited: payload.includeInvited ? payload.includeInvited : false,
      applications: payload.applications ?? [],
      includeInactive: payload.includeInactive ? payload.includeInactive : false,
    };
    return this.httpClient.get<PaginationResponseModel<UserResponseModel>>(this.controllerUrl, {params: queryParams});
  }

  findByIds(payload: { ids: number[] }): Observable<UserResponseModel[]> {
    return this.httpClient.post<UserResponseModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<UserFullResponseModel> {
    return this.httpClient.get<UserFullResponseModel>(`${this.controllerUrl}/${payload.id}`);
  }

  resendRegConfEmail(userId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.controllerUrl}/${userId}/resend-reg-conf-email`, null);
  }

  saveUser(payload: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    language: string;
    userGroups: UserGroupSimpleModel[];
    defaultOrganizationId: number;
    active: boolean;
    organizations: number[];
    userOrganizationApplications: UserOrganizationApplicationModel[];
    pinCode: string;
    logOutTimeInMS: number;
  }): Observable<UserFullResponseModel> {
    const requestData = {
      userId: payload.userId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      username: payload.username,
      language: payload.language,
      userGroups: payload.userGroups,
      defaultOrganizationId: payload.defaultOrganizationId,
      active: payload.active,
      organizations: payload.organizations,
      userOrganizationApplications: payload.userOrganizationApplications,
      pinCode: payload.pinCode,
      logOutTimeInMS: payload.logOutTimeInMS,
    };
    return this.httpClient.patch<UserFullResponseModel>(`${this.controllerUrl}/${payload.userId}`, requestData);
  }

  addUser(payload: {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    organizations: number[];
    posUser: boolean;
  }): Observable<UserResponseModel> {
    const requestData = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      username: payload.username,
      organizations: payload.organizations,
      posUser: payload.posUser,
    };
    return this.httpClient.post<UserResponseModel>(`${this.controllerUrl}`, requestData);
  }

  checkPosLicense(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.controllerUrl}/check-pos-license`);
  }

  importUsersCsvTemplateUrl(): string {
    return this.controllerUrl + '/csv-import-template';
  }

  importWithCsv(payload: { file: File }): Observable<FileUploadingModel<void>> {
    const formData: FormData = new FormData();
    formData.append('file', payload.file);

    return this.fileRestUtilsService.uploadFileRequest(this.controllerUrl + '/import-csv', formData, payload.file.size);
  }

  getLoginActivities(payload: { userId: number }): Observable<UserApplicationLoginActivityResponseModel[]> {
    return this.httpClient.get<UserApplicationLoginActivityResponseModel[]>(`${this.controllerUrl}/${payload.userId}/login-activities`);
  }

  photoUrl(payload: { userId: number }): string {
    return `${this.controllerUrl}/${payload.userId}/photo`;
  }

  photoUrlByUsername(payload: { username: string }): string {
    return `${this.controllerUrl}/username/${payload.username}/photo`;
  }

  uploadPhoto(userPhotoModel: UserPhotoModel): Observable<UserPhotoModel> {
    return this.httpClient.post<UserPhotoModel>(`${this.controllerUrl}/${userPhotoModel.userId}/photo`, userPhotoModel);
  }

  updateUserOrganizationDefaultApplication(payload: { data: UpdateUserOrganizationDefaultApplicationRequestModel }): Observable<UserFullResponseModel> {
    const requestBody = {
      applicationId: payload.data.applicationId,
    };
    return this.httpClient.put<UserFullResponseModel>(
      `${this.controllerUrl}/${payload.data.userId}/organizations/${payload.data.organizationId}/applications/default`,
      requestBody
    );
  }

  checkUserOrganizationApplicationCanActivate(payload: { userId: number; organizationId: number; applicationId: number }): Observable<void> {
    return this.httpClient.get<void>(`${this.controllerUrl}/${payload.userId}/organizations/${payload.organizationId}/applications/${payload.applicationId}/check`);
  }

  makeAdministrator(payload: { userId: number; password: string }): Observable<UserFullResponseModel> {
    return this.httpClient.put<UserFullResponseModel>(`${this.controllerUrl}/${payload.userId}/make-administrator`, {password: payload.password});
  }

  transferOwnership(payload: { userId: number; password: string }): Observable<UserFullResponseModel> {
    return this.httpClient.put<UserFullResponseModel>(`${this.controllerUrl}/${payload.userId}/owner`, {password: payload.password});
  }

  switchActiveOrganization(payload: { activeOrganizationId: number }): Observable<CurrentUserContextResponseModel> {
    return this.httpClient.post<CurrentUserContextResponseModel>(`${this.controllerUrl}/switch-active-organization`, payload.activeOrganizationId);
  }

  updateNotShowConfirmSwitchingDialog(payload: { notShowDialog: boolean }): Observable<void> {
    return this.httpClient.put<void>(`${this.controllerUrl}/current-user/not-show-confirmation-dialog`, {notShowDialog: payload.notShowDialog});
  }

  generatePinCode(): Observable<string> {
    return this.httpClient.get<string>(this.controllerUrl + '/pin-code', {responseType: 'text' as any});
  }
}
