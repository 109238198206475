import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { OrganizationLogoModel } from '@base/model/organization/organization-logo.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import { OrganizationModel } from '@base/modules/rest/organization/model/organization.model';
import { OrgDiagramResponseModel } from '@base/modules/rest/organization/response/org-diagram-response.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationRestService implements PageableRestService<OrganizationMasterDataModel> {

  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/organizations';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  getAll(): Observable<OrganizationMasterDataModel[]> {
    return this.httpClient.get<OrganizationMasterDataModel[]>(`${this.controllerUrl}/all`);
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<OrganizationMasterDataModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.httpClient.get<PaginationResponseModel<OrganizationMasterDataModel>>(this.controllerUrl, { params: queryParams });
  }

  findAllForOverview(payload: { pagination: BeyondPaginationModel; includeInactive?: boolean }): Observable<PaginationResponseModel<OrganizationMasterDataModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
      includeInactive: payload.includeInactive ? payload.includeInactive : false,
    };
    return this.httpClient.get<PaginationResponseModel<OrganizationMasterDataModel>>(this.controllerUrl, { params: queryParams });
  }

  findByIds(payload: { ids: number[] }): Observable<OrganizationMasterDataModel[]> {
    return this.httpClient.post<OrganizationMasterDataModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<OrganizationMasterDataModel> {
    return this.httpClient.get<OrganizationMasterDataModel>(`${this.controllerUrl}/${payload.id}`);
  }

  create(payload: { organization: OrganizationModel }): Observable<OrganizationMasterDataModel> {
    return this.httpClient.post<OrganizationMasterDataModel>(this.controllerUrl, payload.organization);
  }

  update(payload: { id: number; organization: OrganizationModel }): Observable<OrganizationMasterDataModel> {
    return this.httpClient.put<OrganizationMasterDataModel>(`${this.controllerUrl}/${payload.id}`, payload.organization);
  }

  uploadLogo(payload: { organizationId: number; organizationLogo: OrganizationLogoModel }): Observable<OrganizationMasterDataModel> {
    return this.httpClient.post<OrganizationMasterDataModel>(`${this.controllerUrl}/logo/${payload.organizationId}`, payload.organizationLogo);
  }

  getOrganizationDiagramData(): Observable<OrgDiagramResponseModel> {
    return this.httpClient.get<OrgDiagramResponseModel>(`${this.controllerUrl}/diagram`);
  }

  isCodeAvailable(payload: { code: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-code`, `${payload.code}`);
  }
}
