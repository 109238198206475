import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExportSettingsModel } from '@modules/master-data/model/export-settings.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { ImportSettingsModel } from '@shared/components/dialogs/import-dialog/model/import-settings.model';
import { FileRestUtilsService } from '../common/file-rest-utils.service';
import { FileUploadingModel } from '@base/modules/rest/common/model/file-uploading.model';
import { AppConfiguration } from '../../../../app-configuration';
import { ChangeActivityModel } from '../master-data/model/change-activity.model';
import { ObjectDefinitionModel } from '../bbs-object/model/object-definition.model';
import { UserFieldSettingModel } from '../bbs-object/model/user-field-setting.model';
import { ObjectFindRequestModel } from './model/object-find-request.model';
import { ObjectActionModel } from './model/object-action.model';
import { ObjectActionRequestModel } from './model/object-action-request.model';

export class ObjectRestService<E = any> {
  baseUrl = this.appConfiguration.serverApiUrl + '/secured/objects';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration,
              private objectName: string,
              private fileRestUtilsService: FileRestUtilsService) {
  }

  private url(): string {
    return `${this.baseUrl}/${this.objectName}`;
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return this.httpClient.get<ObjectDefinitionModel>(`${this.url()}/definition`);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<E>> {
    return this.httpClient.post<PaginationResponseModel<E>>(`${this.url()}/find`, data);
  }

  findAll(): Observable<E[]> {
    return this.httpClient.get<E[]>(`${this.url()}/all`);
  }

  findByIds(payload: { ids: number[] }): Observable<E[]> {
    return this.httpClient.post<E[]>(`${this.url()}/ids`, payload.ids);
  }

  findOne(payload: { id: number }): Observable<E> {
    return this.httpClient.get<E>(`${this.url()}/${payload.id}`);
  }

  create(payload: { item: E }): Observable<E> {
    const request = {main: payload.item};
    return this.httpClient.post<E>(`${this.url()}`, request);
  }

  update(payload: { id: number; item: E }): Observable<E> {
    const request = {main: payload.item};
    return this.httpClient.put<E>(`${this.url()}/${payload.id}`, request);
  }

  accept(payload: { id: number; item: E }): Observable<any> {
    const request = {main: payload.item};
    return this.httpClient.put<any>(`${this.url()}/${payload.id}/accept`, request);
  }

  cancel(payload: { id: number; item: E }): Observable<any> {
    const request = {main: payload.item};
    return this.httpClient.put<any>(`${this.url()}/${payload.id}/cancel`, request);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.httpClient.delete<void>(`${this.url()}/${payload.id}`);
  }

  changeActivity(payload: { data: ChangeActivityModel }): Observable<E> {
    return this.httpClient.post<E>(`${this.url()}/${payload.data.id}/change-activity`, payload.data);
  }

  doAction(data: ObjectActionRequestModel): Observable<ObjectActionModel> {
    return this.httpClient.post<ObjectActionModel>(`${this.url()}/action`, data);
  }

  export(data: ExportSettingsModel): Observable<ArrayBuffer> {
    return this.httpClient.post<ArrayBuffer>(`${this.url()}/export`, data, {responseType: 'blob' as 'json'});
  }

  import(importSettings: ImportSettingsModel, file: File): Observable<FileUploadingModel<ArrayBuffer>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('importSettings', new Blob(
      [JSON.stringify(importSettings)],
      {type: 'application/json'}
    ));
    return this.fileRestUtilsService.uploadFileRequest<ArrayBuffer>(`${this.url()}/import`, formData, file.size, 'blob' as 'json');
  }

  saveColumns(fields: UserFieldSettingModel[]): Observable<ObjectDefinitionModel> {
    return this.httpClient.post<ObjectDefinitionModel>(`${this.url()}/columns`, fields);
  }

}
