import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileRestUtilsService } from '../common/file-rest-utils.service';
import { RolesRestAdapterService } from '@base/modules/rest/roles/roles-rest-adapter.service';
import { PermissionsRestAdapterService } from '@base/modules/rest/permissions/permissions-rest-adapter.service';
import { McmWizardRestAdapterService } from '@base/modules/rest/mcm-wizard/mcm-wizard-rest-adapter.service';
import { OrganizationRestAdapterService } from '@base/modules/rest/organization/organization-rest-adapter.service';
import { BbsObjectRestAdapterService } from '@base/modules/rest/bbs-object/bbs-object-rest-adapter.service';
import { CustomFieldRestAdapterService } from '@base/modules/rest/custom-field/custom-field-rest-adapter.service';
import { LayoutRestAdapterService } from '@base/modules/rest/layout/layout-rest-adapter.service';
import { UserRestAdapterService } from '../user/user-rest-adapter.service';
import { UserGroupsRestAdapterService } from '../user-groups/user-groups-rest-adapter.service';
import { ObjectNameEnum } from '../master-data-history/model/enums/object-name.enum';
import { AppConfiguration } from '../../../../app-configuration';
import { ObjectRestService } from './object-rest.service';
import { LicenseRestAdapterService } from '@base/modules/rest/license/license-rest-adapter.service';
import {
  UserOnlineActivityRestAdapterService
} from '@base/modules/rest/user/user-online-activity-rest-adapter.service';
import {
  OrganizationDiagramRestAdapterService
} from '@base/modules/rest/organization/organization-diagram-rest-adapter.service';

@Injectable()
export class ObjectRestServiceCreator {

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration,
              private fileRestUtilsService: FileRestUtilsService,
              private userGroupsRestAdapterService: UserGroupsRestAdapterService,
              private userRestAdapterService: UserRestAdapterService,
              private rolesRestAdapterService: RolesRestAdapterService,
              private permissionsRestAdapterService: PermissionsRestAdapterService,
              private mcmWizardRestAdapterService: McmWizardRestAdapterService,
              private organizationRestAdapterService: OrganizationRestAdapterService,
              private customFieldRestAdapterService: CustomFieldRestAdapterService,
              private layoutRestAdapterService: LayoutRestAdapterService,
              private licenseRestAdapterService: LicenseRestAdapterService,
              private userOnlineActivityRestAdapterService: UserOnlineActivityRestAdapterService,
              private organizationDiagramRestAdapterService: OrganizationDiagramRestAdapterService,
              private bbsObjectsRestAdapterService: BbsObjectRestAdapterService) {
  }

  create<T = any>(objectName: string): ObjectRestService<T> {
    if (objectName === ObjectNameEnum.UserGroup) {
      return this.userGroupsRestAdapterService;
    }
    if (objectName === ObjectNameEnum.User) {
      return this.userRestAdapterService;
    }
    if (objectName === ObjectNameEnum.Role) {
      return this.rolesRestAdapterService;
    }
    if (objectName === ObjectNameEnum.Permission) {
      return this.permissionsRestAdapterService;
    }
    if (objectName === ObjectNameEnum.MCMWizard) {
      return this.mcmWizardRestAdapterService;
    }
    if (objectName === ObjectNameEnum.Organization) {
      return this.organizationRestAdapterService;
    }
    if (objectName === ObjectNameEnum.Objects) {
      return this.bbsObjectsRestAdapterService;
    }
    if (objectName === ObjectNameEnum.CustomField) {
      return this.customFieldRestAdapterService;
    }
    if (objectName === ObjectNameEnum.LayoutManager) {
      return this.layoutRestAdapterService;
    }
    if (objectName === ObjectNameEnum.License) {
      return this.licenseRestAdapterService;
    }
    if (objectName === ObjectNameEnum.UserActivity) {
      return this.userOnlineActivityRestAdapterService;
    }
    if (objectName === ObjectNameEnum.OrganizationDiagram) {
      return this.organizationDiagramRestAdapterService;
    }
    return new ObjectRestService<T>(this.httpClient, this.appConfiguration, objectName, this.fileRestUtilsService);
  }
}
