import { ColumnModel, ColumnType, ColumnValidator } from '@shared/components/beyond-table/model/column.model';
import { TranslateService } from '@ngx-translate/core';
import { FieldTranslatePipe } from '@shared/pipes/field-translate.pipe';
import { Workbook } from 'exceljs';
import { EXCEL_SHEET_NAME } from '@views/secured/beyond-smart-reports/services/flexmonster-properties.service';
import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { generateFileName, saveArrayBufferAsFile } from '@shared/util/file.util';
import DxDataGrid from 'devextreme/ui/data_grid';
import { calcTextWidth } from '@shared/util/util';
import { CustomFieldTypesEnum } from '../modules/rest/custom-field/enums/custom-field-types.enum';
import { CustomFieldModel } from '../modules/rest/custom-field/model/custom-field.model';
import { ObjectDefinitionModel } from '../modules/rest/bbs-object/model/object-definition.model';
import { DATE_FORMAT, DATETIME_FORMAT } from './date.util';
import { valueOnPath } from './common.util';
import { DEFAULT_NUMBER_OF_DECIMALS } from './number.util';

export const CREATED = 'created';
export const MODIFIED = 'modified';
export const CREATED_BY = 'createdBy';
export const MODIFIED_BY = 'modifiedBy';
export const STATUS = 'status';
export const NAME = 'name';
export const ACTIONS = 'actions';
export const DESCRIPTION = 'description';
export const ACTIVE = 'active';
export const CODE = 'code';

export const objectColumns = (objectDefinition: ObjectDefinitionModel, translate: TranslateService): ColumnType[] => {
  return objectDefinition.columns
    .map(column => {
      const field = objectDefinition.fields?.find(item => item.dbName === column);
      if (!field) {
        return {
          type: CustomFieldTypesEnum.TEXT,
          label: column,
          dbName: column,
          readOnly: true,
        } as CustomFieldModel;
      }
      return field;
    })
    .map((f: CustomFieldModel) => toTableColumn(f, translate))
    .map((c: ColumnType) => {
      if (!c.width) {
        c.width = calcTextWidth(c.caption, '14px Roboto') + 40;
      }
      return c;
    });
};

const toTableColumn = (field: CustomFieldModel, translate: TranslateService): ColumnType => {
  const fieldTranslatePipe = new FieldTranslatePipe(translate);
  const label = fieldTranslatePipe.transform(field);
  return {
    name: field.dbName,
    dataField: toDataField(field),
    dataType: toColumnDataType(field),
    allowEditing: !field.readOnly,
    format: toColumnFormat(field),
    width: field.columnWidth,
    caption: label,
    allowReordering: true,
    calculateCellValue: toCalculateCellValue(field),
    validationRules: columnValidations(field),
    lookup: mapComboFixedColumns(field),
    allowGrouping: false,
  };
};

const toDataField = (field: CustomFieldModel): string => {
  return field.dbName;
};

const columnValidations = (field: CustomFieldModel): ColumnValidator[] => {
  const validators: ColumnValidator[] = [];
  if (field.mandatory) {
    validators.push({type: 'required'});
  }
  return validators;
};

const toCalculateCellValue = (field: CustomFieldModel): any => {
  if (field.type === CustomFieldTypesEnum.SWITCHER || field.type === CustomFieldTypesEnum.CHECKBOX || field.type === CustomFieldTypesEnum.RADIO_BUTTON) {
    return (item: any) => {
      return !!valueOnPath(item, field.dbName);
    };
  }

  if (!field.lookupObject) {
    return undefined;
  }
  if (field.lookupObject && field.type !== CustomFieldTypesEnum.LOOKUP) {
    return (item: any) => valueOnPath(item, field.dbName);
  }
  return (item: any) => {
    if (!item) {
      return '';
    }
    const value = valueOnPath(item, field.dbName);
    if (value) {
      return `${valueOnPath(value, field.lookupObject.mainField)} ${valueOnPath(value, field.lookupObject.secondField)}`;
    }
    return '';
  };
};

const toColumnDataType = (field: CustomFieldModel): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' => {
  if (field.type === CustomFieldTypesEnum.DATE) {
    return 'datetime';
  }
  if (field.type === CustomFieldTypesEnum.DATE_TIME) {
    return 'datetime';
  }
  if (field.type === CustomFieldTypesEnum.NUMBER || field.type === CustomFieldTypesEnum.DECIMAL_NUMBER) {
    return 'number';
  }
  if (field.type === CustomFieldTypesEnum.SWITCHER || field.type === CustomFieldTypesEnum.CHECKBOX || field.type === CustomFieldTypesEnum.RADIO_BUTTON) {
    return 'boolean';
  }
  return 'string';
};

const toColumnFormat = (field: CustomFieldModel): string => {
  if (field.type === CustomFieldTypesEnum.DATE) {
    return DATE_FORMAT;
  }
  if (field.type === CustomFieldTypesEnum.DATE_TIME) {
    return DATETIME_FORMAT;
  }
  if (field.type === CustomFieldTypesEnum.DECIMAL_NUMBER) {
    const numberOfDecimals = field.numberOfDecimals || DEFAULT_NUMBER_OF_DECIMALS;
    const decimalZeros = '0'.repeat(numberOfDecimals);
    return `,##0.${decimalZeros}`;
  }
  return null;
};

const mapComboFixedColumns = (field: CustomFieldModel): any => {
  if (field.type !== CustomFieldTypesEnum.SELECT) {
    return null;
  }
  return {
    dataSource: field.options,
    valueExpr: 'value',
    displayExpr: 'label',
  };
};

export function groupColumns(columns: ColumnModel[], groupByColumns: string[]): ColumnModel[] {
  if (!groupByColumns) {
    return columns;
  }
  let i = 0;
  for (const groupByColumn of groupByColumns) {
    const column = columns.find(c => c.dataField === groupByColumn);
    column.groupIndex = i++;
    column.allowGrouping = true;
  }
  return columns;
}

export function exportTableData(component: DxDataGrid, fileName: string): void {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(EXCEL_SHEET_NAME);

  exportDataGrid({
    component: component,
    worksheet,
    autoFilterEnabled: true,
    keepColumnWidths: true,
    loadPanel: {
      enabled: false,
    },
    customizeCell: (options) => {
      exportDataGridCellCustomizationOption(options);
    },
  })
    .then(() => {
      workbook.xlsx.writeBuffer()
        .then((buffer: ArrayBuffer) => {
          saveArrayBufferAsFile(buffer, generateFileName(fileName, '.xlsx'));
        });
    });
}

function exportDataGridCellCustomizationOption(options: any): void {
  const gridCell: DataGridCell = options.gridCell;
  const excelCell = options.excelCell;

  // Style the entire table
  excelCell.font = {size: 9, name: 'Calibri'};
  excelCell.alignment = {vertical: 'middle'};
  if (gridCell.column.alignment) {
    excelCell.alignment.horizontal = gridCell.column.alignment;
  }
  excelCell.format = gridCell.column.format;

  // Style header row
  if (gridCell.rowType === 'header') {
    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'd0cece'}};
    excelCell.alignment = {horizontal: 'left', vertical: 'middle'};
    excelCell.font.bold = true;
  }

  // Style group row
  if (gridCell.rowType === 'group') {
    excelCell.fill = {type: 'pattern', pattern: 'solid', bgColor: {argb: 'e9e9e9'}};
    excelCell.font.bold = true;
    excelCell.alignment = {horizontal: 'left', vertical: 'middle'};
  }

  // Style total summary row
  if (gridCell.rowType === 'totalFooter' && excelCell.value) {
    excelCell.font = {size: 9, bold: true, italic: true};
  }
}
