import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { FilterItemModel } from '@base/modules/rest/smart-reports/model/filter-item.model';
import { SmartReportExecuteSqlModel } from '@base/modules/rest/smart-reports/model/smart-report-execute-sql.model';
import { SmartReportModel } from '@base/modules/rest/smart-reports/model/smart-report.model';
import { SmartReportPaginationModel } from '@views/secured/beyond-smart-reports/modules/model/smart-report-pagination.model';
import { Observable } from 'rxjs';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class SmartReportRestService {

  private readonly smartReportsUrl: string = this.appConfiguration.serverApiUrl + '/secured/smart-reports';

  constructor(private http: HttpClient, private appConfiguration: AppConfiguration) {
  }

  findSmartReportsByPaginationAndFilterAndReportView(payload: { pagination: SmartReportPaginationModel }): Observable<PaginationResponseModel<SmartReportModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter,
      reportView: payload.pagination.reportView,
    };
    return this.http.get<PaginationResponseModel<SmartReportModel>>(this.smartReportsUrl, { params: queryParams });
  }

  getOne(payload: { id: number }): Observable<SmartReportModel> {
    return this.http.get<SmartReportModel>(`${this.smartReportsUrl}/${payload.id}`);
  }

  saveSmartReport(smartReport: SmartReportModel): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(this.smartReportsUrl, smartReport);
  }

  updateSmartReport(smartReport: SmartReportModel): Observable<SmartReportModel> {
    return this.http.put<SmartReportModel>(`${this.smartReportsUrl}/${smartReport.id}`, smartReport);
  }

  copySmartReport(payload: { id: number; smartReport: SmartReportModel }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.id}/copy`, payload.smartReport);
  }

  changeActivity(payload: { data: { smartReportId: number; active: boolean }[] }): Observable<SmartReportModel[]> {
    return this.http.post<SmartReportModel[]>(`${this.smartReportsUrl}/change-activity`, payload.data);
  }

  deleteReport(id: number): Observable<number> {
    return this.http.delete<number>(`${this.smartReportsUrl}/${id}`);
  }

  deleteReports(smartReports: number[]): Observable<any> {
    return this.http.post<any>(`${this.smartReportsUrl}/bulk`, smartReports);
  }

  checkReport(smartReport: SmartReportModel): Observable<SmartReportExecuteSqlModel> {
    return this.http.post<SmartReportExecuteSqlModel>(`${this.smartReportsUrl}/execute-sql`, smartReport);
  }

  getSmartReportResultSet(payload: { id: number; filters: FilterItemModel[] }): Observable<any> {
    return this.http.post(`${this.smartReportsUrl}/result-set`, {
      'filters': payload.filters,
      'reportId': payload.id,
    });
  }

  saveSettings(payload: { smartReportId: number; settings: string }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/settings`, payload.settings);
  }

  saveSettingsForRole(payload: { smartReportId: number; smartReportRoleId: number; settings: string }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/${payload.smartReportRoleId}/settings`, payload.settings);
  }

  renameSmartReportRole(payload: { smartReportId: number; smartReportRoleId: number; name: string }): Observable<SmartReportModel> {
    return this.http.post<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/${payload.smartReportRoleId}/name`, payload.name);
  }

  setDefaultSmartReportRole(payload: { smartReportId: number; smartReportRoleId: number }): Observable<SmartReportModel> {
    return this.http.patch<SmartReportModel>(`${this.smartReportsUrl}/${payload.smartReportId}/smart-report-roles/default`, { 'roleId': payload.smartReportRoleId });
  }

  getLocalizationJson(lang: string): Observable<any> {
    return this.http.get(`assets/i18n-flexmonster/${lang}.json`);
  }
}
