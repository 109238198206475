export const DEFAULT_NUMBER_OF_DECIMALS = 2;
export const PRECIZNOST_KAMATA = 8;
export const PRECIZNOST_VREDNOSTI = 2;
export const PRECIZNOST_CENA = 3;
export const PRECIZNOST_CENA_NABAVKA = 5;
export const PRECIZNOST_CENA_PRODAJA = 5;
export const PRECIZNOST_KOLICINE = 3;
export const PRECIZNOST_PROCENTA = 2;
export const PRECIZNOST_DEVIZNE_CENE = 4;
export const PRECIZNOST_KURSA = 4;
export const PRECIZNOST_ODNOSA_JM = 6;
export const PRECIZNOST_VREMENA = 3;
export const PRECIZNOST_KOLICINE_DUGACKO = 5;
export const BEZ_ZAOKRUZIVANJA = 18;
export const PRECIZNOST_PROSECNE_OCENE = 2;
export const PRECIZNOST_NULA = 0;
export const PRECIZNOST_JEDAN = 1;
export const PRECIZNOST_MASE = 5;
export const GEO_SIRINA = 5;
export const GEO_DUZINA = 5;

export const TIP_ZAOKRUZIVANJA = 6;

export const NULA = 0;
export const JEDAN = 1;
export const MINUS_JEDAN = -1;
export const DESET = 10;
export const DVADESET = 20;
export const STO = 100;
export const HILJADA = 1000;

export const EPSILON = 0.000001;

export function round(val: number, decimalPlaces: number = DEFAULT_NUMBER_OF_DECIMALS): number {
  const d = Math.pow(DESET, decimalPlaces);
  return Math.round((val + Number.EPSILON) * d) / d;
}

export function priceWithRabate(price: number, rabatRate: number, decimalPlaces: number = DEFAULT_NUMBER_OF_DECIMALS): number {
  if (!price) {
    price = 0;
  }
  if (!rabatRate) {
    rabatRate = 0;
  }
  let priceWr = price * (JEDAN - rabatRate / STO);
  priceWr = round(priceWr, decimalPlaces);
  return priceWr;
}

export function valueWithTax(value: number, taxRate: number, decimalPlaces: number = DEFAULT_NUMBER_OF_DECIMALS): number {
  if (!value) {
    value = 0;
  }
  if (!taxRate) {
    taxRate = 0;
  }
  let val = value * (JEDAN + taxRate / STO);
  val = round(val, decimalPlaces);
  return val;
}

export function valueWithoutTax(value: number, taxRate: number, decimalPlaces: number = DEFAULT_NUMBER_OF_DECIMALS): number {
  if (!value) {
    value = 0;
  }
  if (!taxRate) {
    taxRate = 0;
  }
  let val = value / (JEDAN + taxRate / STO);
  val = round(val, decimalPlaces);
  return val;
}

export function taxValue(value: number, taxRate: number, decimalPlaces: number = DEFAULT_NUMBER_OF_DECIMALS): number {
  if (!value) {
    value = 0;
  }
  if (!taxRate) {
    taxRate = 0;
  }
  let val = value * (taxRate / STO);
  val = round(val, decimalPlaces);
  return val;
}

export function getCena(preciznost: number, price: number): number {
  if (!price) {
    price = 0;
  }

  return round(price, preciznost);
}

export function getVrednost(price: number): number {
  if (!price) {
    price = 0;
  }

  return round(price, PRECIZNOST_VREDNOSTI);
}

export function pomnoziVrednosti(value1: number, value2: number, preciznost: number): number {
  return round((value1 || 0) * (value2 || 0), preciznost);
}

export function getKolicinaDugacko(amount: number): number {
  if (!amount) {
    amount = 0;
  }

  return round(amount, PRECIZNOST_KOLICINE_DUGACKO);
}

export function getBrojDecimala(value: number): number {
  if (!value) {
    return NULA;
  }

  const brojDecimalaSplit = value.toString().split('.');
  let brojDecimala = '';
  if (brojDecimalaSplit.length > JEDAN) {
    brojDecimala = brojDecimalaSplit.at(JEDAN);
  }

  return brojDecimala.length;
}

export function getProcenat(procenat: number): number {
  return round(procenat, PRECIZNOST_PROCENTA);
}

export function getKolicina(amount: number): number {
  if (!amount) {
    amount = 0;
  }

  return round(amount, PRECIZNOST_KOLICINE);
}

export function numbersEqual(firstNumber: number, secondNumber: number): boolean {
  const diff = Math.abs(firstNumber - secondNumber);
  return diff === 0 || diff <= EPSILON;
}
