import { MenuModel } from '@base/modules/rest/application/model/menu.model';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { PermissionActionEnum } from '@base/modules/rest/permissions/enums/permission-action.enum';
import { SystemRoles } from '@base/modules/rest/user/enum/system-roles.enum';

export const IDENTITY_MENU: MenuModel = {
  menuItems: [
    {
      name: 'IDENTITY_HOME',
      menuItems: [],
    },
    {
      name: 'USER_MANAGEMENT',
      menuItems: [
        {
          name: ObjectNameEnum.User,
          permission: {view: ObjectNameEnum.User, actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]},
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
        {
          name: ObjectNameEnum.UserGroup,
          permission: {view: ObjectNameEnum.UserGroup, actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]},
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
      ],
      onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
    },
    {
      name: 'AUTHORIZATION',
      menuItems: [
        {
          name: ObjectNameEnum.Role,
          permission: {view: ObjectNameEnum.Role, actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]},
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
        {
          name: ObjectNameEnum.Permission,
          permission: {
            view: ObjectNameEnum.Permission,
            actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]
          },
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
      ],
      onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
    },
    {
      name: 'ORGANIZATION',
      menuItems: [
        {
          name: ObjectNameEnum.Organization,
          permission: {
            view: ObjectNameEnum.Organization,
            actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]
          },
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
        {
          name: ObjectNameEnum.OrganizationDiagram,
          permission: {
            view: ObjectNameEnum.OrganizationDiagram,
            actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]
          },
          object: true,
          menuItems: [],
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
        },
      ],
    },
    {
      name: ObjectNameEnum.License,
      permission: {view: ObjectNameEnum.License, actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]},
      menuItems: [],
      object: true,
      onlyVisibleTo: [SystemRoles.OWNER],
    },
    {
      name: 'REPORTS',
      onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
      menuItems: [
        {
          name: ObjectNameEnum.UserActivity,
          permission: {
            view: ObjectNameEnum.UserActivity,
            actions: [PermissionActionEnum.FULL, PermissionActionEnum.VIEW]
          },
          onlyVisibleTo: [SystemRoles.OWNER, SystemRoles.ADMINISTRATOR],
          object: true,
          menuItems: [],
        },
      ],
    },
  ],
};
