import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ThemeEnum } from '../enums/theme.enum';
import { ThemeModel } from '../model/theme.model';
import { THEMES_LIST } from '../themes-list';

@Directive({
  selector: '[appTheme]',
})
export class ThemeDirective implements OnChanges {
  @Input() appTheme: ThemeEnum;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appTheme']) {
      const currentTheme = THEMES_LIST.find((t: ThemeModel) => t.name === this.appTheme);
      if (!currentTheme) {
        console.error(`Theme ${this.appTheme} is not defined.`);
      } else {
        THEMES_LIST
          .forEach(theme => this.renderer.removeClass(this.elementRef.nativeElement, theme.className));
        this.renderer.addClass(this.elementRef.nativeElement, currentTheme.className);
      }
    }
  }
}
