const ThemeEnum = {
  THEME_SYSTEM_ERROR: 'THEME_SYSTEM_ERROR' as 'THEME_SYSTEM_ERROR',
  THEME_SYSTEM_INFO: 'THEME_SYSTEM_INFO' as 'THEME_SYSTEM_INFO',
  THEME_SYSTEM_SUCCESS: 'THEME_SYSTEM_SUCCESS' as 'THEME_SYSTEM_SUCCESS',
  THEME_SYSTEM_WARNING: 'THEME_SYSTEM_WARNING' as 'THEME_SYSTEM_WARNING',
  THEME_BEYOND_ACCOUNTING: 'THEME_BEYOND_ACCOUNTING' as 'THEME_BEYOND_ACCOUNTING',
  THEME_BEYOND_ASSETS: 'THEME_BEYOND_ASSETS' as 'THEME_BEYOND_ASSETS',
  THEME_BEYOND_BACK_OFFICE: 'THEME_BEYOND_BACK_OFFICE' as 'THEME_BEYOND_BACK_OFFICE',
  THEME_BEYOND_BUSINESS_SUITE: 'THEME_BEYOND_BUSINESS_SUITE' as 'THEME_BEYOND_BUSINESS_SUITE',
  THEME_BEYOND_COMPLAINT: 'THEME_BEYOND_COMPLAINT' as 'THEME_BEYOND_COMPLAINT',
  THEME_BEYOND_CORE: 'THEME_BEYOND_CORE' as 'THEME_BEYOND_CORE',
  THEME_BEYOND_FINANCE: 'THEME_BEYOND_FINANCE' as 'THEME_BEYOND_FINANCE',
  THEME_BEYOND_GOVERNMENT: 'THEME_BEYOND_GOVERNMENT' as 'THEME_BEYOND_GOVERNMENT',
  THEME_BEYOND_IDENTITY: 'THEME_BEYOND_IDENTITY' as 'THEME_BEYOND_IDENTITY',
  THEME_BEYOND_INVENTORY: 'THEME_BEYOND_INVENTORY' as 'THEME_BEYOND_INVENTORY',
  THEME_BEYOND_LOGISTIC: 'THEME_BEYOND_LOGISTIC' as 'THEME_BEYOND_LOGISTIC',
  THEME_BEYOND_LOYALTY: 'THEME_BEYOND_LOYALTY' as 'THEME_BEYOND_LOYALTY',
  THEME_BEYOND_MANUFACTURING: 'THEME_BEYOND_MANUFACTURING' as 'THEME_BEYOND_MANUFACTURING',
  THEME_BEYOND_MOBILE_TIME_MANAGEMENT: 'THEME_BEYOND_MOBILE_TIME_MANAGEMENT' as 'THEME_BEYOND_MOBILE_TIME_MANAGEMENT',
  THEME_BEYOND_MONITORING: 'THEME_BEYOND_MONITORING' as 'THEME_BEYOND_MONITORING',
  THEME_BEYOND_PARTNERS: 'THEME_BEYOND_PARTNERS' as 'THEME_BEYOND_PARTNERS',
  THEME_BEYOND_PAYROLL: 'THEME_BEYOND_PAYROLL' as 'THEME_BEYOND_PAYROLL',
  THEME_BEYOND_PEOPLE: 'THEME_BEYOND_PEOPLE' as 'THEME_BEYOND_PEOPLE',
  THEME_BEYOND_PERFORMANCE: 'THEME_BEYOND_PERFORMANCE' as 'THEME_BEYOND_PERFORMANCE',
  THEME_BEYOND_PLAN: 'THEME_BEYOND_PLAN' as 'THEME_BEYOND_PLAN',
  THEME_BEYOND_POS: 'THEME_BEYOND_POS' as 'THEME_BEYOND_POS',
  THEME_BEYOND_PROCUREMENT: 'THEME_BEYOND_PROCUREMENT' as 'THEME_BEYOND_PROCUREMENT',
  THEME_BEYOND_QUALITY_CONTROL: 'THEME_BEYOND_QUALITY_CONTROL' as 'THEME_BEYOND_QUALITY_CONTROL',
  THEME_BEYOND_RETAIL: 'THEME_BEYOND_RETAIL' as 'THEME_BEYOND_RETAIL',
  THEME_BEYOND_SMART_REPORTS: 'THEME_BEYOND_SMART_REPORTS' as 'THEME_BEYOND_SMART_REPORTS',
  THEME_BEYOND_WARRANTY: 'THEME_BEYOND_WARRANTY' as 'THEME_BEYOND_WARRANTY',
  THEME_BLUE: 'THEME_BLUE' as 'THEME_BLUE',
  THEME_GRAY: 'THEME_GRAY' as 'THEME_GRAY',
  THEME_GREEN: 'THEME_GREEN' as 'THEME_GREEN',
  THEME_ORANGE: 'THEME_ORANGE' as 'THEME_ORANGE',
  THEME_RED: 'THEME_RED' as 'THEME_RED',
  THEME_YELLOW: 'THEME_YELLOW' as 'THEME_YELLOW',
};
type ThemeEnum = (typeof ThemeEnum)[keyof typeof ThemeEnum];
export {ThemeEnum};
