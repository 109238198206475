import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { BbsObjectRestService } from '@base/modules/rest/bbs-object/bbs-object-rest.service';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { NAME } from '@base/utils/table-data.util';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable({
  providedIn: 'root',
})
export class BbsObjectRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private bbsObjectRestService: BbsObjectRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.Objects, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.Objects,
      mainField: NAME,
      columns: ['name', 'dbName', 'type'],
      fields: [],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.bbsObjectRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
      includeNonMcm: data.params?.includeNonMcm,
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.bbsObjectRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.bbsObjectRestService.create({bbsObject: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.bbsObjectRestService.update({id: payload.id, bbsObject: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    throw new Error('Method not implemented');
  }
}
