import { ThemeEnum } from './enums/theme.enum';
import { ThemeModel } from './model/theme.model';

export const THEMES_LIST: ThemeModel[] = [
  {name: ThemeEnum.THEME_SYSTEM_ERROR, className: 'theme-system-error'},
  {name: ThemeEnum.THEME_SYSTEM_INFO, className: 'theme-system-info'},
  {name: ThemeEnum.THEME_SYSTEM_SUCCESS, className: 'theme-system-success'},
  {name: ThemeEnum.THEME_SYSTEM_WARNING, className: 'theme-system-warning'},
  {name: ThemeEnum.THEME_BEYOND_ACCOUNTING, className: 'theme-beyond-accounting'},
  {name: ThemeEnum.THEME_BEYOND_ASSETS, className: 'theme-beyond-assets'},
  {name: ThemeEnum.THEME_BEYOND_BACK_OFFICE, className: 'theme-beyond-back-office'},
  {name: ThemeEnum.THEME_BEYOND_BUSINESS_SUITE, className: 'theme-beyond-business-suite'},
  {name: ThemeEnum.THEME_BEYOND_COMPLAINT, className: 'theme-beyond-complaint'},
  {name: ThemeEnum.THEME_BEYOND_CORE, className: 'theme-beyond-core'},
  {name: ThemeEnum.THEME_BEYOND_FINANCE, className: 'theme-beyond-finance'},
  {name: ThemeEnum.THEME_BEYOND_GOVERNMENT, className: 'theme-beyond-government'},
  {name: ThemeEnum.THEME_BEYOND_IDENTITY, className: 'theme-beyond-identity'},
  {name: ThemeEnum.THEME_BEYOND_INVENTORY, className: 'theme-beyond-inventory'},
  {name: ThemeEnum.THEME_BEYOND_LOGISTIC, className: 'theme-beyond-logistic'},
  {name: ThemeEnum.THEME_BEYOND_LOYALTY, className: 'theme-beyond-loyalty'},
  {name: ThemeEnum.THEME_BEYOND_MANUFACTURING, className: 'theme-beyond-manufacturing'},
  {name: ThemeEnum.THEME_BEYOND_MOBILE_TIME_MANAGEMENT, className: 'theme-beyond-mobile-time-management'},
  {name: ThemeEnum.THEME_BEYOND_MONITORING, className: 'theme-beyond-monitoring'},
  {name: ThemeEnum.THEME_BEYOND_PARTNERS, className: 'theme-beyond-partners'},
  {name: ThemeEnum.THEME_BEYOND_PAYROLL, className: 'theme-beyond-payroll'},
  {name: ThemeEnum.THEME_BEYOND_PEOPLE, className: 'theme-beyond-people'},
  {name: ThemeEnum.THEME_BEYOND_PERFORMANCE, className: 'theme-beyond-performance'},
  {name: ThemeEnum.THEME_BEYOND_PLAN, className: 'theme-beyond-plan'},
  {name: ThemeEnum.THEME_BEYOND_POS, className: 'theme-beyond-pos'},
  {name: ThemeEnum.THEME_BEYOND_PROCUREMENT, className: 'theme-beyond-procurement'},
  {name: ThemeEnum.THEME_BEYOND_QUALITY_CONTROL, className: 'theme-beyond-quality-control'},
  {name: ThemeEnum.THEME_BEYOND_RETAIL, className: 'theme-beyond-retail'},
  {name: ThemeEnum.THEME_BEYOND_SMART_REPORTS, className: 'theme-beyond-smart-reports'},
  {name: ThemeEnum.THEME_BEYOND_WARRANTY, className: 'theme-beyond-warranty'},
  {name: ThemeEnum.THEME_BLUE, className: 'theme-blue'},
  {name: ThemeEnum.THEME_GRAY, className: 'theme-gray'},
  {name: ThemeEnum.THEME_GREEN, className: 'theme-green'},
  {name: ThemeEnum.THEME_ORANGE, className: 'theme-orange'},
  {name: ThemeEnum.THEME_RED, className: 'theme-red'},
  {name: ThemeEnum.THEME_YELLOW, className: 'theme-yellow'},
];

export function getThemesClassNames(): string[] {
  return THEMES_LIST
    .map(theme => theme.className);
}

export function getThemeClassName(theme: ThemeEnum): string {
  const item = THEMES_LIST
    .find(t => t.name === theme);

  if (!item) {
    console.error(`Not defined theme class for theme '${theme}'`);
    return '';
  }

  return item.className;
}
