import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserRestService } from '@base/modules/rest/user/user-rest.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoggedInResponseModel } from '@base/modules/rest/user/response/logged-in-response.model';
import { redirectToUrl } from '@base/utils/url-util';

@Injectable({
  providedIn: 'root',
})
export class PublicPagesCanActivateGuard implements CanActivate {

  constructor(private router: Router, private userRestService: UserRestService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url.includes('continue')) {
      return of(true);
    }
    const addToken: boolean = route.queryParams['addToken'];
    const redirectUrl: string = route.queryParams['redirectUrl'];
    return this.userRestService.isUserLoggedIn()
      .pipe(
        catchError(() => of(false)),
        tap((response: LoggedInResponseModel) => {
          if (!!response) {
            redirectToUrl({
              router: this.router,
              redirectUrl: redirectUrl,
              addToken: addToken,
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
            });
          }
        }),
        map((loggedIn) => !loggedIn)
      );
  }
}
