import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRestService } from '@base/modules/rest/user/user-rest.service';
import { LOGIN_PAGE } from '@base/services/app-routes.service';
import { AppState } from '@base/store';
import { CorePreloadActions } from '@base/store/preload';
import { CoreUiSelectors } from '@base/store/ui';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecuredPagesCanActivateGuard implements CanActivate {
  loadingData$ = this.store.select(CoreUiSelectors.loadingData);

  constructor(private store: Store<AppState>, private router: Router, private userRestService: UserRestService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userRestService.isUserLoggedIn()
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        tap((loggedIn: boolean) => {
          if (loggedIn) {
            this.store.dispatch(CorePreloadActions.LoadAppSecuredData());
          } else {
            this.router.navigate([LOGIN_PAGE], { queryParams: { redirectUrl: state.url } });
          }
        }),
        mergeMap(() => this.loadingData$),
        skipWhile(loading => loading),
        take(1),
        map(() => true)
      );
  }
}
