import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { RoleModel } from '@base/modules/rest/roles/model/role.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class RolesRestService implements PageableRestService<RoleModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/roles';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<RoleModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.httpClient.get<PaginationResponseModel<RoleModel>>(this.controllerUrl, { params: queryParams });
  }

  findByIds(payload: { ids: number[] }): Observable<RoleModel[]> {
    return this.httpClient.post<RoleModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<RoleModel> {
    return this.httpClient.get<RoleModel>(`${this.controllerUrl}/${payload.id}`);
  }

  create(payload: { role: RoleModel }): Observable<RoleModel> {
    return this.httpClient.post<RoleModel>(this.controllerUrl, payload.role);
  }

  update(payload: { id: number; role: RoleModel }): Observable<RoleModel> {
    return this.httpClient.put<RoleModel>(`${this.controllerUrl}/${payload.id}`, payload.role);
  }

  delete(payload: { ids: number[] }): Observable<void> {
    return this.httpClient.put<void>(`${this.controllerUrl}/delete-bulk`, payload.ids);
  }
}
