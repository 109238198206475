import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { LayoutRestService } from '@base/modules/rest/layout/layout-rest.service';
import { Injectable } from '@angular/core';
import { createField } from '@base/modules/rest/custom-field/model/custom-field.model';
import { CustomFieldTypesEnum } from '@base/modules/rest/custom-field/enums/custom-field-types.enum';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class LayoutRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private layoutRestService: LayoutRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.LayoutManager, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.LayoutManager,
      mainField: 'name',
      columns: ['name', 'bbsObject.name', 'bbsObject.type', 'status'],
      fields: [
        createField({label: 'Objekat', dbName: 'bbsObject.name', type: CustomFieldTypesEnum.TEXT, translated: true}),
        createField({
          label: 'Tip objekta',
          dbName: 'bbsObject.type',
          type: CustomFieldTypesEnum.TEXT,
          translated: true
        }),
        createField({label: 'Status', dbName: 'status', type: CustomFieldTypesEnum.SWITCHER, translated: true}),
      ],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.layoutRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.layoutRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.layoutRestService.create({layout: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.layoutRestService.update({id: payload.id, layout: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    return this.layoutRestService.delete({id: payload.id});
  }

  findAll(): Observable<any[]> {
    throw new Error('Not implemented method');
  }
}
