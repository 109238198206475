import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformLanguage',
})
export class TransformLanguagePipe implements PipeTransform {

    transform(value: string): string {
      switch (value) {
        case 'en':
          return 'English';
        case 'lat':
          return 'Srpski (latinica)';
        case 'cir':
          return 'Српски (ћирилица)';
        case 'hrv':
          return 'Hrvatski';
        case 'slo':
          return 'Slovenian';
        default:
          return null;
      }
    }
}
