import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { FieldNameTranslatePipe } from '@base/pipes/field-name-translate.pipe';

@Pipe({
  name: 'fieldTranslate',
})
export class FieldTranslatePipe implements PipeTransform {
  fieldNameTranslatePipe = new FieldNameTranslatePipe(this.translateService);

  constructor(private translateService: TranslateService) {
  }

  transform(field: CustomFieldModel): string {
    const label = field?.label;
    if (field?.translated && label != null) {
      return label;
    } else {
      return this.fieldNameTranslatePipe.transform(field?.dbName);
    }
  }
}

@NgModule({
  exports: [
    FieldTranslatePipe,
  ],
  declarations: [
    FieldTranslatePipe,
  ],
})
export class FieldTranslatePipeModule {
}
