import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuiCardComponent } from './bui-card.component';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    BuiCardComponent
  ],
  exports: [
    BuiCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,

  ]
})
export class BuiCardModule { }
