import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationRegisteredResponseModel } from '@base/modules/rest/registration/response/organization-registered-response.model';
import { AppConfiguration } from 'app/app-configuration';
import { DeviceInfo } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationRestService {

  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/public/registration';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  isOrganizationRegistered(): Observable<OrganizationRegisteredResponseModel> {
    return this.httpClient.get<OrganizationRegisteredResponseModel>(this.controllerUrl + '/status');
  }

  isUsernameAvailable(payload: { username: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-username`, `${payload.username}`);
  }

  isEmailAvailable(payload: { email: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-email`, `${payload.email}`);
  }

  registerOrganization(payload: {
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    language: string;
  }): Observable<void> {
    const requestData = {
      organizationName: payload.organizationName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      username: payload.username,
      language: payload.language,
    };
    return this.httpClient.post<void>(this.controllerUrl + '/organization', requestData);
  }

  resendOrganizationRegistrationEmail(payload: { language: string }): Observable<void> {
    const requestData = {
      language: payload.language,
    };
    return this.httpClient.post<void>(this.controllerUrl + '/resend-orgreg-email', requestData);
  }

  tokenValidity(payload: { token: string }): Observable<any> {
    return this.httpClient.post<any>(this.controllerUrl + '/token/valid', { token: payload.token });
  }

  finishRegistration(payload: { token: string; password: string; pinCode: string; deviceInfo: DeviceInfo }): Observable<void> {
    const requestData = {
      token: payload.token,
      password: payload.password,
      pinCode: payload.pinCode,
      deviceInfoDto: {
        browser: payload.deviceInfo.browser,
        browserVersion: payload.deviceInfo.browser_version,
        device: payload.deviceInfo.device,
        os: payload.deviceInfo.os,
        osVersion: payload.deviceInfo.os_version,
      },
      clientId: 'BEYOND_IDENTITY',
    };
    return this.httpClient.post<void>(this.controllerUrl + '/finish', requestData);
  }

  generatePinCode(token: string): Observable<string> {
    return this.httpClient.post<string>(this.controllerUrl + '/pin-code', token);
  }
}
