import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { UserFullResponseModel } from '@base/modules/rest/user/response/user-full-response.model';
import { createAction, props } from '@ngrx/store';
import { OrganizacijaModel } from '../../modules/rest/mis4/O/organizacija.model';

export const Logout = createAction(
  '[Core - user] Logout'
);

export const LogoutSuccess = createAction(
  '[Core - user] Logout success'
);

export const ReloadUserContext = createAction(
  '[Core - user] Reload user context'
);

export const ReloadUserContextSuccess = createAction(
  '[Core - user] Reload user context success',
  props<{ userContext: CurrentUserContextResponseModel }>()
);

export const SetUserContext = createAction(
  '[Core - user] Set user context',
  props<{ userContext: UserFullResponseModel }>()
);

export const SetUserPhoto = createAction(
  '[Core - user] Set user photo',
  props<{ userId: number; photoUrl: string }>()
);

export const UpdateUserOrganizationDefaultApplication = createAction(
  '[Core - user] Update user organization default application',
  props<{ userId: number; organizationId: number; applicationId: number }>()
);

export const UpdateUserOrganizationDefaultApplicationSuccess = createAction(
  '[Core - user] Update user organization default application success',
  props<{ user: UserFullResponseModel }>()
);

export const SwitchActiveOrganization = createAction(
  '[Core - user] Switch active organization',
  props<{ activeOrganizationId: number }>()
);

export const SwitchActiveOrganizationSuccess = createAction(
  '[Core - user] Switch active organization success',
  props<{ currentUserContextResponse: CurrentUserContextResponseModel }>()
);

export const LoadActiveOrganizacijaSuccess = createAction(
  '[Core - user] Load active organizacija success',
  props<{ organizacija: OrganizacijaModel }>()
);
