import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class LicenseRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration) {
    super(httpClient, appConfiguration, ObjectNameEnum.License, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.License,
      fields: [],
    } as ObjectDefinitionModel);
  }
}
