import { ObjectCompositeFilterModel } from '@base/modules/rest/object/model/object-composite-filter.model';
import { ObjectFilterItemModel } from '@shared/components/filter/model/object-filter-item.model';
import { FilterOperationEnum } from '@shared/components/filter/enums/filter-operation.enum';
import { ExpressionOperatorEnum } from '@base/modules/rest/object/enums/expression-operator.enum';
import { CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { CustomFieldTypesEnum } from '@base/modules/rest/custom-field/enums/custom-field-types.enum';
import { pathGetLast } from '@base/utils/common.util';
import * as moment from 'moment-timezone';
import { clearTime, MAT_DATE_FORMAT } from '@base/utils/date.util';

export function prepareCompositeFilter(compositeFilter: ObjectCompositeFilterModel, filter: string, fields: CustomFieldModel[]): ObjectCompositeFilterModel {
  if (!!filter?.trim().length) {
    return prepareSearchFilter(filter, fields);
  }
  return {
    filters: compositeFilter?.filters?.map(f => {
      if (f?.value?.id) {
        f = {
          ...f,
          value: f?.value?.id,
        };
      }
      return f;
    }),
    expression: compositeFilter?.expression,
  };
}

export function prepareSearchFilter(filter: string, fields: CustomFieldModel[]): ObjectCompositeFilterModel {
  if (!filter || filter.length === 0) {
    return null;
  }
  const compositeFilter = {filters: [], expression: ''};
  fields
    .filter(field => field.visible && field.filterable)
    .forEach((field, i) => {
      if (field.type === CustomFieldTypesEnum.LOOKUP) {
        const mainFieldFilter = createFieldFilter(`item_${i}_1`, field.path, field?.lookupObject?.mainField, filter);
        const secondFieldFilter = createFieldFilter(`item_${i}_2`, field.path, field?.lookupObject?.secondField, filter);
        compositeFilter.filters = [...compositeFilter.filters, mainFieldFilter, secondFieldFilter];
      } else if (field.type === CustomFieldTypesEnum.DATE) {
        const dateValue = moment(filter, MAT_DATE_FORMAT, true);
        const isDateValid = dateValue.isValid();
        if (isDateValid) {
          const filterItem: ObjectFilterItemModel = createFieldFilterItem(`item_${i}`, field, clearTime(dateValue), FilterOperationEnum.EQUALS);
          compositeFilter.filters = [...compositeFilter.filters, filterItem];
        }
      } else {
        const filterItem: ObjectFilterItemModel = createFieldFilterItem(`item_${i}`, field, filter);
        compositeFilter.filters = [...compositeFilter.filters, filterItem];
      }
    });
  generateCompositeFilterExpression(compositeFilter, ExpressionOperatorEnum.OR);
  return compositeFilter;
}

export function createFieldFilter(name: string, path: string, fieldName: string, filter: any, operator?: FilterOperationEnum): ObjectFilterItemModel {
  return {
    name,
    beanName: path,
    fieldName: fieldName,
    operation: operator ?? FilterOperationEnum.CONTAINS,
    value: filter,
  };
}

export function createFieldFilterItem(name: string,
                                      field: CustomFieldModel,
                                      filter: any,
                                      operator?: FilterOperationEnum): ObjectFilterItemModel {
  let fieldName;
  if (field.type === CustomFieldTypesEnum.LOOKUP) {
    fieldName = 'id';
  } else {
    fieldName = pathGetLast(field.dbName);
  }
  const _operator = operator ?? (fieldName === 'id' ? FilterOperationEnum.EQUALS : FilterOperationEnum.CONTAINS);
  return {
    name: name,
    beanName: field.path,
    fieldName: fieldName,
    operation: _operator,
    value: filter,
  };
}

export function generateCompositeFilterExpression(compositeFilter: ObjectCompositeFilterModel,
                                                  expressionOperator: ExpressionOperatorEnum): void {
  compositeFilter.expression = '';
  compositeFilter.filters
    .forEach((f: ObjectFilterItemModel, i: number, array: ObjectFilterItemModel[]) => {
        const snippet = i !== array.length - 1 ? `${f.name} ${expressionOperator} ` : `${f.name}`;
        compositeFilter.expression = compositeFilter.expression.concat(snippet);
      }
    );
}
