export enum CustomFieldTypesEnum {
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  PHONE = 'PHONE',
  SELECT = 'SELECT',
  SWITCHER = 'SWITCHER',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  URL = 'URL',
  MULTISELECT = 'MULTISELECT',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CHECKBOX = 'CHECKBOX',
  DATE_TIME = 'DATE_TIME',
  DATE_PERIOD = 'DATE_PERIOD',
  DATE_TIME_PERIOD = 'DATE_TIME_PERIOD',
  NUMERATION = 'NUMERATION',
  LOOKUP = 'LOOKUP',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
  TIME = 'TIME',
  ANALITIKE = 'ANALITIKE',
  KONTO_ANALITIKE = 'KONTO_ANALITIKE',
}
