import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_LOCALE_ID } from '@shared/util/util';
import { valueOnPath } from '../utils/common.util';
import { CustomFieldModel } from '../modules/rest/custom-field/model/custom-field.model';
import { CustomFieldTypesEnum } from '../modules/rest/custom-field/enums/custom-field-types.enum';
import { FormatDatePipe } from './format-date.pipe';

@Pipe({
  name: 'recordDisplayValue',
})
export class RecordDisplayValuePipe implements PipeTransform {

  formatDatePipe = new FormatDatePipe(DEFAULT_LOCALE_ID);

  transform(item: any, object: { mainField?: string; secondField?: string; fields?: CustomFieldModel[] }): string {
    if (!item) {
      return '';
    }
    if (!object?.mainField && !object?.secondField) {
      console.error('Object mainField and secondField not defined!');
      return '';
    }
    const mainField = this.findField(object, object?.mainField);
    const secondField = this.findField(object, object?.secondField);
    const mainFieldValue = valueOnPath(item, object?.mainField);
    const secondFieldValue = valueOnPath(item, object?.secondField);
    return `${this.formatValue(mainField, mainFieldValue)} ${this.formatValue(secondField, secondFieldValue)}`;
  }

  private findField(object: { fields?: CustomFieldModel[] }, dbName: string): CustomFieldModel {
    if (object?.fields) {
      return object.fields
        .find(field => field.dbName === dbName);
    }
    return null;
  }

  private formatValue(field: CustomFieldModel, value: any): any {
    if (!field) {
      return value;
    }
    if (field.type === CustomFieldTypesEnum.DATE) {
      if (!value) {
        return value;
      }
      return this.formatDatePipe.transform(value);
    }
    return value;
  }
}
