import { Injectable } from '@angular/core';
import { ObjectRestService } from '@base/modules/rest/object/object-rest.service';
import { HttpClient } from '@angular/common/http';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { RolesRestService } from '@base/modules/rest/roles/roles-rest.service';
import { Observable, of } from 'rxjs';
import { ObjectDefinitionModel } from '@base/modules/rest/bbs-object/model/object-definition.model';
import { DESCRIPTION, NAME } from '@base/utils/table-data.util';
import { ObjectFindRequestModel } from '@base/modules/rest/object/model/object-find-request.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable({
  providedIn: 'root',
})
export class RolesRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private rolesRestService: RolesRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.Role, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.Role,
      mainField: NAME,
      columns: [NAME, DESCRIPTION],
      fields: [],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.rolesRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.rolesRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.rolesRestService.create({role: payload.item});
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.rolesRestService.update({id: payload.id, role: payload.item});
  }

  delete(payload: { id: number }): Observable<void> {
    return this.rolesRestService.delete({ids: [payload.id]});
  }
}
