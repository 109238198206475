import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environments/environment';
import { AppConfiguration } from 'app/app-configuration';
import { AppModule } from 'app/app.module';

fetch('./assets/env.json')
  .then((response) => response.json())
  .then((config: AppConfiguration) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {
        provide: AppConfiguration,
        useFactory: () => {
          if (!config.serverApiUrl.startsWith('http')) {
            config.serverApiUrl = `${window.location.origin}/${config.serverApiUrl}`;
          }
          return config;
        },
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
