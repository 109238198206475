import { Pipe, PipeTransform } from '@angular/core';
import { ObjectNameEnum } from '@base/modules/rest/master-data-history/model/enums/object-name.enum';
import { CustomFieldModel } from '../modules/rest/custom-field/model/custom-field.model';

@Pipe({
  name: 'generateLookupObjectName',
})
export class GenerateLookupObjectNamePipe implements PipeTransform {

  transform(fields: CustomFieldModel[], arg: string): ObjectNameEnum {
    const field = fields.find(f => f.dbName === arg);
    return field.lookupObject?.name as ObjectNameEnum;
  }

}
