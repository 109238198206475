import { createAction, props } from '@ngrx/store';

export const OpenBeyondSuite = createAction(
  '[Login page] Open beyond suite',
  props<{ url: string; clientId: string }>()
);

export const OpenBeyondSuiteSuccess = createAction(
  '[Login page] Open beyond suite success',
  props<{ url: string; token: string; clientId: string }>()
);
