import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LicenseResponseModel } from '@base/modules/rest/license/response/license-response.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicenseRestService {

  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/organization/license';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  saveLicense(licenseResponseModel: LicenseResponseModel): Observable<LicenseResponseModel> {
    return this.httpClient.post<LicenseResponseModel>(this.controllerUrl, licenseResponseModel);
  }

  getLicense(): Observable<LicenseResponseModel> {
    return this.httpClient.get<LicenseResponseModel>(this.controllerUrl);
  }

  hasActiveLicense(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.controllerUrl}/has-active`);
  }
}
