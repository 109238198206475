import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ObjectRestService } from '../object/object-rest.service';
import { AppConfiguration } from '../../../../app-configuration';
import { ObjectNameEnum } from '../master-data-history/model/enums/object-name.enum';
import { ObjectDefinitionModel } from '../bbs-object/model/object-definition.model';
import { ObjectFindRequestModel } from '../object/model/object-find-request.model';
import { PaginationResponseModel } from '../../../model/common/pagination-response.model';
import { UserRestService } from './user-rest.service';

@Injectable()
export class UserRestAdapterService extends ObjectRestService {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration,
              private userRestService: UserRestService) {
    super(httpClient, appConfiguration, ObjectNameEnum.User, null);
  }

  getDefinition(): Observable<ObjectDefinitionModel> {
    return of({
      name: ObjectNameEnum.User,
      mainField: 'fullName',
      fields: [],
    } as ObjectDefinitionModel);
  }

  find(data: ObjectFindRequestModel): Observable<PaginationResponseModel<any>> {
    return this.userRestService.findAll({
      pagination: {
        ...data.page,
        filter: data.filter,
      },
      includeInvited: data.params?.includeInvited,
      includeInactive: data.params?.includeInactive,
    });
  }

  findOne(payload: { id: number }): Observable<any> {
    return this.userRestService.findOne(payload);
  }

  create(payload: { item: any }): Observable<any> {
    return this.userRestService.addUser(payload.item);
  }

  update(payload: { id: number; item: any }): Observable<any> {
    return this.userRestService.saveUser(payload.item);
  }

  delete(payload: { id: number }): Observable<void> {
    throw new Error('Not implemented method');
  }

  findAll(): Observable<any[]> {
    throw new Error('Not implemented method');
  }
}
