import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { McmWizardModel } from '@base/modules/rest/mcm-wizard/model/mcm-wizard.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class McmWizardRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/mcm-wizards';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<McmWizardModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter,
    };
    return this.httpClient.get<PaginationResponseModel<McmWizardModel>>(this.controllerUrl, { params: queryParams });
  }

  create(payload: { mcmWizard: McmWizardModel }): Observable<McmWizardModel> {
    return this.httpClient.post<McmWizardModel>(`${this.controllerUrl}`, payload.mcmWizard);
  }

  update(payload: { id: number; mcmWizard: McmWizardModel }): Observable<McmWizardModel> {
    return this.httpClient.put<McmWizardModel>(`${this.controllerUrl}/${payload.id}`, payload.mcmWizard);
  }

  getOne(payload: { id: number }): Observable<McmWizardModel> {
    return this.httpClient.get<McmWizardModel>(`${this.controllerUrl}/${payload.id}`);
  }

  delete(payload: { ids: number[] }): Observable<void> {
    return this.httpClient.put<void>(`${this.controllerUrl}/delete-bulk`, payload.ids);
  }
}
