import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';
import { ActuatorInfoModel } from '@base/modules/rest/actuator/model/actuator-info.model';

@Injectable()
export class ActuatorRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/actuator';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  info(): Observable<ActuatorInfoModel> {
    return this.httpClient.get<ActuatorInfoModel>(`${this.controllerUrl}/info`);
  }
}
