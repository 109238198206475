import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PriceListItemModel } from '@base/modules/rest/price-list/model/price-list-item.model';
import { PriceListRetailStoreModel } from '@base/modules/rest/price-list/model/price-list-retail-store.model';
import { PriceListCustomerModel } from '@base/modules/rest/price-list/model/price-list-customer.model';
import { AppConfiguration } from '../../../../app-configuration';
import { AbstractMasterDataRestService } from '../master-data/abstract-master-data-rest.service';
import { PriceListModel } from './model/price-list.model';

@Injectable()
export class PriceListRestService extends AbstractMasterDataRestService<PriceListModel> {

  constructor(httpClient: HttpClient,
              appConfiguration: AppConfiguration) {
    super(httpClient, appConfiguration.serverApiUrl + '/secured/price-lists');
  }

  getAllItems(payload: { priceListId: number }): Observable<PriceListItemModel[]> {
    return this.httpClient.get<PriceListItemModel[]>(`${this.controllerUrl}/${payload.priceListId}/items/all`);
  }

  saveItems(payload: { priceListId: number; items: PriceListItemModel[] }): Observable<PriceListModel> {
    return this.httpClient.put<PriceListModel>(`${this.controllerUrl}/${payload.priceListId}/items`, payload.items);
  }

  deleteItem(payload: { priceListId: number; itemId: number }): void {
    this.httpClient.delete<void>(`${this.controllerUrl}/${payload.priceListId}/items/${payload.itemId}`);
  }

  getAllCustomers(payload: { priceListId: number }): Observable<PriceListCustomerModel[]> {
    return this.httpClient.get<PriceListCustomerModel[]>(`${this.controllerUrl}/${payload.priceListId}/customers/all`);
  }

  saveCustomers(payload: { priceListId: number; items: PriceListCustomerModel[] }): Observable<PriceListModel> {
    return this.httpClient.put<PriceListModel>(`${this.controllerUrl}/${payload.priceListId}/customers`, payload.items);
  }

  deleteCustomer(payload: { priceListId: number; customerId: number }): void {
    this.httpClient.delete<void>(`${this.controllerUrl}/${payload.priceListId}/customers/${payload.customerId}`);
  }

  getAllRetailStores(payload: { priceListId: number }): Observable<PriceListRetailStoreModel[]> {
    return this.httpClient.get<PriceListRetailStoreModel[]>(`${this.controllerUrl}/${payload.priceListId}/retail-stores/all`);
  }

  saveRetailStores(payload: { priceListId: number; retailStores: PriceListRetailStoreModel[] }): Observable<PriceListModel> {
    return this.httpClient.put<PriceListModel>(`${this.controllerUrl}/${payload.priceListId}/retail-stores`, payload.retailStores);
  }

  deleteRetailStore(payload: { priceListId: number; retailStore: number }): void {
    this.httpClient.delete<void>(`${this.controllerUrl}/${payload.priceListId}/retail-stores/${payload.retailStore}`);
  }
}
