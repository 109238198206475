import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cyrillic',
})
export class CyrillicPipe implements PipeTransform {

  transform(latin: string): string {
    let cyr: string;
    const Lat2Cyr = {
      'a': 'а',
      'b': 'б',
      'c': 'ц',
      'd': 'д',
      'e': 'е',
      'f': 'ф',
      'g': 'г',
      'h': 'х',
      'i': 'и',
      'j': 'ј',
      'k': 'к',
      'l': 'л',
      'm': 'м',
      'n': 'н',
      'o': 'о',
      'p': 'п',
      'q': 'кј',
      'r': 'р',
      's': 'с',
      't': 'т',
      'u': 'у',
      'v': 'в',
      'w': 'в',
      'x': 'кс',
      'y': 'и',
      'z': 'з',

      'A': 'А',
      'B': 'Б',
      'C': 'Ц',
      'D': 'Д',
      'E': 'Е',
      'F': 'Ф',
      'G': 'Г',
      'H': 'Х',
      'I': 'И',
      'J': 'Ј',
      'K': 'К',
      'L': 'Л',
      'M': 'М',
      'N': 'Н',
      'O': 'О',
      'P': 'П',
      'Q': '',
      'R': 'Р',
      'S': 'С',
      'T': 'Т',
      'U': 'У',
      'V': 'В',
      'W': 'В',
      'X': 'КС',
      'Y': 'И',
      'Z': 'З',

      'č': 'ч',
      'ć': 'ћ',
      'đ': 'ђ',
      'ž': 'ж',
      'š': 'ш',

      'Č': 'Ч',
      'Ć': 'Ћ',
      'Đ': 'Ђ',
      'Ž': 'Ж',
      'Š': 'Ш',
    };
    if (latin !== undefined) {
      cyr = latin
        .split('')
        .map((char) => Lat2Cyr[char] || char)
        .join('');
    }
    return cyr;
  }
}
