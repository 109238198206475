export enum ObjectNameEnum {
  AktivacijaOS = 'AktivacijaOS',
  AktivacijaOSSt = 'AktivacijaOSSt',
  AmortizacijaOS = 'AmortizacijaOS',
  AmortizacijaOSSt = 'AmortizacijaOSSt',
  AmortizacionaGrupa = 'AmortizacionaGrupa',
  AmortizacionaGrupaSt = 'AmortizacionaGrupaSt',
  AmortizacionaPodgrupaOS = 'AmortizacionaPodgrupaOS',
  AmortizacionaPodgrupaOSSt = 'AmortizacionaPodgrupaOSSt',
  AmortizacionaPodgrupaOSStKnjigaAm = 'AmortizacionaPodgrupaOSStKnjigaAm',
  AnalitickaKarticaRobeUMagacinu = 'AnalitickaKarticaRobeUMagacinu',
  Analitika = 'Analitika',
  AopKonto = 'AopKonto',
  AopKontoSt = 'AopKontoSt',
  Application = 'Application',
  AvansiDobavljaca = 'AvansiDobavljaca',
  AvansiKupaca = 'AvansiKupaca',
  Banka = 'Banka',
  BankovniRacunLica = 'BankovniRacunLica',
  Blagajna = 'Blagajna',
  BrutoBilans = 'BrutoBilans',
  Category = 'Category',
  ControllingGrupaKontaKonto = 'ControllingGrupaKontaKonto',
  Country = 'Country',
  Currency = 'Currency',
  CustomField = 'CustomField',
  Customer = 'Customer',
  DevopsManagement = 'DevopsManagement',
  District = 'District',
  DnevnikBlagajne = 'DnevnikBlagajne',
  DnevnikGlavneKnjige = 'DnevnikGlavneKnjige',
  Dokument = 'Dokument',
  DokumentBlagajneSt = 'DokumentBlagajneSt',
  DokumentDokumenta = 'DokumentDokumenta',
  Drzava = 'Drzava',
  Eksploatacije = 'Eksploatacije',
  ElektronskiIzvod = 'ElektronskiIzvod',
  ExchangeList = 'ExchangeList',
  FiscalYear = 'FiscalYear',
  GrupaKarakteristikaRobe = 'GrupaKarakteristikaRobe',
  GrupaLinijeDistribucije = 'GrupaLinijeDistribucije',
  GrupaTipovaKarakteristika = 'GrupaTipovaKarakteristika',
  GrupnaObradaFaktura = 'GrupnaObradaFaktura',
  InternaSifraPlacanja = 'InternaSifraPlacanja',
  IsplatnicaBlagajne = 'IsplatnicaBlagajne',
  IzlazneAvansneUplate = 'IzlazneAvansneUplate',
  IzlazniAvansniRacun = 'IzlazniAvansniRacun',
  IzlazniAvansniRacunSt = 'IzlazniAvansniRacunSt',
  IzlazniDokument = 'IzlazniDokument',
  IzlazniDokumentSt = 'IzlazniDokumentSt',
  IzlazniUsluga = 'IzlazniUsluga',
  IzlazniUslugaSt = 'IzlazniUslugaSt',
  IzvestajPoLokacji = 'IzvestajPoLokaciji',
  IzvestajPoLokacijiDialog = 'IzvestajPoLokacijiDialog',
  IzlazniRacunOS = 'IzlazniRacunOS',
  IzlazniRacunOSSt = 'IzlazniRacunOSSt',
  IzvestajZaKvalitetaPoVrstiProizvoda = 'IzvestajZaKvalitetaPoVrstiProizvoda',
  Izvod1 = 'Izvod1',
  IzvodSt1 = 'IzvodSt1',
  Karakteristika = 'Karakteristika',
  KarakteristikeRobe = 'KarakteristikeRobe',
  KarticaDobavljacaFinansijska = 'KarticaDobavljacaFinansijska',
  KarticaDobavljacaFinansijskaDevizna = 'KarticaDobavljacaFinansijskaDevizna',
  KarticaKatPodOS = 'KarticaKatPodOS',
  KarticaKupcaFinansijska = 'KarticaKupcaFinansijska',
  KarticaKupcaFinansijskaDevizna = 'KarticaKupcaFinansijskaDevizna',
  KarticaOS = 'KarticaOS',
  KarticaOSPoreska = 'KarticaOSPoreska',
  KarticaOSPriprema = 'KarticaOSPriprema',
  KlasaPartnera1 = 'KlasaPartnera1',
  KlasaPartnera1NadredjenaKlasa = 'KlasaPartnera1NadredjenaKlasa',
  KlasaRobe1 = 'KlasaRobe1',
  KlasaRobe1NadredjenaKlasa = 'KlasaRobe1NadredjenaKlasa',
  KlasaRobe1RobnaGrupa = 'KlasaRobe1RobnaGrupa',
  KlasifikacijaOS = 'KlasifikacijaOS',
  KnjigaAmortizacije = 'KnjigaAmortizacije',
  KnjigaAmortizacijeSt = 'KnjigaAmortizacijeSt',
  KnjigaIzdatihRacuna = 'KnjigaIzdatihRacuna',
  KnjigaIzlaznihRacunaPDVa1 = 'KnjigaIzlaznihRacunaPDVa1',
  KnjigaUlaznihRacunaPDVa1 = 'KnjigaUlaznihRacunaPDVa1',
  KnjigaPrimljenihRacuna = 'KnjigaPrimljenihRacuna',
  KnjiznoPismo = 'KnjiznoPismo',
  KnjiznoPismoSt = 'KnjiznoPismoSt',
  Komentar = 'Komentar',
  KontniPlan = 'KontniPlan',
  KontoKontnogPlana = 'KontoKontnogPlana',
  KorekcijaGlavneKnjige = 'KorekcijaGlavneKnjige',
  KursnaListaCentralneBanke = 'KursnaListaCentralneBanke',
  KursnaListaCentralneBankeSt = 'KursnaListaCentralneBankeSt',
  LayoutManager = 'LayoutManager',
  License = 'License',
  LinijaDistribucije1 = 'LinijaDistribucije1',
  LinijaDistribucijeStVoziloMesto = 'LinijaDistribucijeStVoziloMesto',
  LokacijaMagacina = 'LokacijaMagacina',
  LokacijaOS = 'LokacijaOS',
  MCMWizard = 'McmWizard',
  Magacin = 'Magacin',
  Menu = 'Menu',
  MenuItem = 'MenuItem',
  MestoTroska = 'MestoTroska',
  Municipality = 'Municipality',
  NacinPopisaOS = 'NacinPopisaOS',
  NadlezniZaOS = 'NadlezniZaOS',
  NalKnjPlateAH = 'NalKnjPlateAH',
  NalKnjPlateAHSt = 'NalKnjPlateAHSt',
  Nalog = 'Nalog',
  NivoKlaseRobe1 = 'NivoKlaseRobe1',
  Normativ = 'Normativ',
  NosiocTroskaOsnovni = 'NosiocTroskaOsnovni',
  Objects = 'Objects',
  ObjectAttachment = 'ObjectAttachment',
  ObracunKursnihRazlika = 'ObracunKursnihRazlika',
  ObracunKursnihRazlikaSt = 'ObracunKursnihRazlikaSt',
  ObracunataAmortizacija = 'ObracunataAmortizacija',
  Opstina = 'Opstina',
  Organizacija = 'Organizacija',
  Organization = 'Organization',
  OrganizacionaJedinica = 'OrganizacionaJedinica',
  OrganizationDiagram = 'OrganizationDiagram',
  OsamStope = 'OsamStope',
  OsnovUplateIsplate = 'OsnovUplateIsplate',
  OsnovUplateIsplateDodatniSati = 'OsnovUplateIsplateDodatniSati',
  OsnovnoSredstvo = 'OsnovnoSredstvo',
  OsnovnoSredstvoDelatnost = 'OsnovnoSredstvoDelatnost',
  OsnovnoSredstvoServisi = 'OsnovnoSredstvoServisi',
  OsnovnoSredstvoTrosCen = 'OsnovnoSredstvoTrosCen',
  OtvaranjeZatvorenihStavkiGK = 'OtvaranjeZatvorenihStavkiGK',
  OtvoreneStavkeGlavneKnjige = 'OtvoreneStavkeGlavneKnjige',
  Pakovanje2 = 'Pakovanje2',
  ParametriPlacanjaSvrhaDozVirman = 'ParametriPlacanjaSvrhaDozVirman',
  PaymentCard = 'PaymentCard',
  PeriodKnjizenja = 'PeriodKnjizenja',
  Permission = 'Permission',
  Place = 'Place',
  PlanPlacanja = 'PlanPlacanja',
  PodaciKlase = 'PodaciKlase',
  PodaciKlasePartnera = 'PodaciKlasePartnera',
  PodrucjePrimeneTarifneGrupe = 'PodrucjePrimeneTarifneGrupe',
  PopdvprijavaNew = 'PopdvprijavaNew',
  PoreskaAmortizacija = 'PoreskaAmortizacija',
  PoreskaPrijava = 'PoreskaPrijava',
  PoreskaPrijavaKnjizeneStavke = 'PoreskaPrijavaKnjizeneStavke',
  PoreskaPrijavaSt = 'PoreskaPrijavaSt',
  PoslovnaGodina = 'PoslovnaGodina',
  PoslovniPartnerPravnoLice = 'PoslovniPartnerPravnoLice',
  PravnoLiceOrganizacija = 'PravnoLiceOrganizacija',
  PregledKnjizenjaNaKontu = 'PregledKnjizenjaNaKontu',
  PregledPrometaDobavljaca = 'PregledPrometaDobavljaca',
  PregledPrometaKupaca = 'PregledPrometaKupaca',
  PregledKnjizenjaNaKarticamaKupca = 'PregledKnjizenjaNaKarticamaKupca',
  PregledPrometaKontaPoAnalitikama = 'PregledPrometaKontaPoAnalitikama',
  PregledKnjizenjaNaKarticamaDobavljaca = 'PregledKnjizenjaNaKarticamaDobavljaca',
  PriceList = 'PriceList',
  PrijemnicaTroskovnica = 'PrijemnicaTroskovnica',
  PrijemnicaTroskovnicaSt = 'PrijemnicaTroskovnicaSt',
  PrintTemplate = 'PrintTemplate',
  Product = 'Product',
  ProOperacija = 'ProOperacija',
  PromenaPlanskeCene = 'PromenaPlanskeCene1',
  PromenaPlanskeCeneSt = 'PromenaPlanskeCene1St',
  PutniNalogKomercijalni = 'PutniNalogKomercijalni',
  PutniNalogKomercijalniAkontacije = 'PutniNalogKomercijalniAkontacije',
  PutniRacun = 'PutniRacun',
  PutniRacunKomercijalni = 'PutniRacunKomercijalni',
  PutniRacunSt = 'PutniRacunSt',
  RacOrgSvrhaDozVirman = 'RacOrgSvrhaDozVirman',
  RacunOrganizacije = 'RacunOrganizacije',
  RacunOrganizacijeRadnik = 'RacunOrganizacijeRadnik',
  RacunUsluga1 = 'RacunUsluga1',
  RacunUslugaSt1 = 'RacunUslugaSt1',
  RadniNalogPro = 'RadniNalogPro',
  RashodOS = 'RashodOS',
  RashodOSSt = 'RashodOSSt',
  Region = 'Region',
  Register = 'Register',
  Republic = 'Republic',
  Republika = 'Republika',
  RetailOrder = 'RetailOrder',
  RetailStore = 'RetailStore',
  RobaPakovanje = 'RobaPakovanje',
  RobaUsluga = 'RobaUsluga',
  RobnaGrupa = 'RobnaGrupa',
  RobnaGrupaLokacijaMagacina = 'RobnaGrupaLokacijaMagacina',
  Role = 'Role',
  RucniNalog = 'RucniNalog',
  RucniNalogSt = 'RucniNalogSt',
  SemaZaKnjizenjePDV = 'SemaZaKnjizenjePDV',
  Serija = 'Serija',
  SerijaRobe = 'SerijaRobe',
  SerijaStatus = 'SerijaStatus',
  SmartReport = 'SmartReport',
  SmartReportRole = 'SmartReportRole',
  SmartReportUser = 'SmartReportUser',
  SpisakKompletaOS = 'SpisakKompletaOS',
  StandardniTekstoviDokumenta = 'StandardniTekstoviDokumenta',
  StandardnoKnjizenjeRobe = 'StandardnoKnjizenjeRobe',
  StandardnoKnjizenjeKnjiznogPisma = 'StandardnoKnjizenjeKnjiznogPisma',
  StatusDokumenta = 'StatusDokumenta',
  StavkaNaloga = 'StavkaNaloga',
  StrategijskaPoslovnaJedinica = 'StrategijskaPoslovnaJedinica',
  SvrhaDoznakeZaVirman = 'SvrhaDoznakeZaVirman',
  TarifaPoreza = 'TarifaPoreza',
  TarifnaGrupaPoreza = 'TarifnaGrupaPoreza',
  TaxArea = 'TaxArea',
  TaxExemption = 'TaxExemption',
  TaxRate = 'TaxRate',
  TipAutomatskogZatvaranja = 'TipAutomatskogZatvaranja',
  TipKarakteristike = 'TipKarakteristike',
  TipZoneMagacina = 'TipZoneMagacina',
  TypeOfBusiness = 'TypeOfBusiness',
  UgovorONabavci1 = 'UgovorONabavci1',
  UlazNabavke = 'UlazNabavke',
  UlazNabavkeSt = 'UlazNabavkeSt',
  UlazneAvansneUplate = 'UlazneAvansneUplate',
  UlazniAvansniRacun = 'UlazniAvansniRacun',
  UlazniAvansniRacunSt = 'UlazniAvansniRacunSt',
  UlazniDokumenti = 'UlazniDokumenti',
  UlazniDokumentiSt = 'UlazniDokumentiSt',
  UlazniRacun1 = 'UlazniRacun1',
  UlazniRacunOS = 'UlazniRacunOS',
  UnitOfMeasure = 'UnitOfMeasure',
  UplatnicaBlagajne = 'UplatnicaBlagajne',
  User = 'User',
  UserActivity = 'UserActivity',
  UserGroup = 'UserGroup',
  UslovPlacanja = 'UslovPlacanja',
  Vaga = 'Vaga',
  Valuta = 'Valuta',
  Virman = 'Virman',
  VrednostObracuna = 'VrednostObracuna',
  VrstaAnalitike = 'VrstaAnalitike',
  VrstaAnalitikeNaKontu = 'VrstaAnalitikeNaKontu',
  VrstaDokumenta = 'VrstaDokumenta',
  VrstaDokumentaVeza = 'VrstaDokumentaVeza',
  VrstaIsplateSluzbenaPutovanja = 'VrstaIsplateSluzbenaPutovanja',
  VrstaIzlaznihUsluga = 'VrstaIzlaznihUsluga',
  VrstaKarticeZaBanku = 'VrstaKarticeZaBanku',
  VrstaKnjizenjaKursnihRazlika = 'VrstaKnjizenjaKursnihRazlika',
  VrstaKnjizenjaKursnihRazlikaSt = 'VrstaKnjizenjaKursnihRazlikaSt',
  VrstaKnjizenjaZaOS = 'VrstaKnjizenjaZaOS',
  VrstaKontaZaOS = 'VrstaKontaZaOS',
  VrstaMerenja = 'VrstaMerenja',
  VrstaNosiocaTroska = 'VrstaNosiocaTroska',
  VrstaObSvrhaDozVirman = 'VrstaObSvrhaDozVirman',
  VrstaObaveza = 'VrstaObaveza',
  VrstaPDVKontaPoreza = 'VrstaPDVKontaPoreza',
  VrstaPracenjaSerije = 'VrstaPracenjaSerije',
  VrstaPracenjaSerijeVrstaDokumenta = 'VrstaPracenjaSerijeVrstaDokumenta',
  VrstaTroska1 = 'VrstaTroska1',
  VrstaTroska2 = 'VrstaTroska2',
  Warehouse = 'Warehouse',
  WarehouseType = 'WarehouseType',
  ZahtevZaSluzbenoPutovanjeMestoPutovanja = 'ZahtevZaSluzbenoPutovanjeMestoPutovanja',
  ZakljucniListPeriodicniSt = 'ZakljucniListPeriodicniSt',
  ZatvaranjeRazgranicenja = 'ZatvaranjeRazgranicenja',
  ZatvaranjeRazgranicenjaSt = 'ZatvaranjeRazgranicenjaSt',
  ZonaMagacina = 'ZonaMagacina',
}
