import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fieldNameTranslate',
  standalone: true,
})
export class FieldNameTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string): string {
    const res: string = this.translateService.instant(`FIELDS.${value}`);
    if (res.startsWith('FIELDS.')) {
      return value;
    }
    return res;
  }
}
