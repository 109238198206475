import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { Observable } from 'rxjs';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { Params } from '@angular/router';
import { CustomFieldModel } from '@base/modules/rest/custom-field/model/custom-field.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { AppConfiguration } from '../../../../app-configuration';

@Injectable()
export class CustomFieldRestService implements PageableRestService<CustomFieldModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/custom-fields';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  findByIds(payload: { ids: number[] }): Observable<CustomFieldModel[]> {
    return this.httpClient.post<CustomFieldModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<CustomFieldModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.httpClient.get<PaginationResponseModel<CustomFieldModel>>(this.controllerUrl, {params: queryParams});
  }

  create(payload: { customField: CustomFieldModel }): Observable<CustomFieldModel> {
    return this.httpClient.post<CustomFieldModel>(`${this.controllerUrl}`, payload.customField);
  }

  update(payload: { customField: CustomFieldModel }): Observable<CustomFieldModel> {
    return this.httpClient.put<CustomFieldModel>(`${this.controllerUrl}/${payload.customField.id}`, payload.customField);
  }

  findOne(payload: { id: number }): Observable<CustomFieldModel> {
    return this.httpClient.get<CustomFieldModel>(`${this.controllerUrl}/${payload.id}`);
  }

  delete(payload: { id: number }): Observable<void> {
    return this.httpClient.delete<void>(`${this.controllerUrl}/${payload.id}`);
  }

  isNameAvailable(payload: { name: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.controllerUrl}/available-name`, `${payload.name}`);
  }

  getNameSystemField(): Observable<CustomFieldModel> {
    return this.httpClient.get<CustomFieldModel>(`${this.controllerUrl}/name-system-field`);
  }

  getCodeSystemField(): Observable<CustomFieldModel> {
    return this.httpClient.get<CustomFieldModel>(`${this.controllerUrl}/code-system-field`);
  }
}
