import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { ApplicationNameEnum } from '../../../enums/application-name.enum';
import { UsersOnlineActivityAtTimeResponseModel } from './response/users-online-activity-at-time-response.model';

@Injectable()
export class UserOnlineActivityRestService {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/users/online-activities';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  create(): Observable<void> {
    const params: Params = {
      applicationName: ApplicationNameEnum.BEYOND_IDENTITY,
    };
    return this.httpClient.post<void>(this.controllerUrl, {}, {params});
  }

  getOnlineActivities(): Observable<UsersOnlineActivityAtTimeResponseModel[]> {
    return this.httpClient.get<UsersOnlineActivityAtTimeResponseModel[]>(this.controllerUrl);
  }
}
