import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-bui-placeholder',
  templateUrl: './bui-placeholder.component.html',
  styleUrls: ['./bui-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuiPlaceholderComponent {
  @Input() iconName: string;
  @Input() title: string;
  @Input() description: string;
  @Input() hasTop: boolean;
  @Input() hasBottom: boolean;
}
