import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { OrganizationMasterDataModel } from '@base/modules/rest/organization/model/organization-master-data.model';
import {
  OrganizationRegisteredResponseModel
} from '@base/modules/rest/registration/response/organization-registered-response.model';
import { CurrentUserContextResponseModel } from '@base/modules/rest/user/response/current-user-context-response.model';
import { createAction, props } from '@ngrx/store';
import { ActuatorInfoModel } from '@base/modules/rest/actuator/model/actuator-info.model';
import { OrganizacijaModel } from '../../modules/rest/mis4/O/organizacija.model';
import { StrategijskaPoslovnaJedinicaModel } from '../../modules/rest/mis4/S/strategijska-poslovna-jedinica.model';
import { PoslovnaGodinaModel } from '../../modules/rest/mis4/P/poslovna-godina.model';

export const LoadAppPublicData = createAction(
  '[Core - preload] Load app public data'
);

export const LoadAppPublicDataSuccess = createAction(
  '[Core - preload] Load app public data success',
  props<{
    organizationRegisteredResponse: OrganizationRegisteredResponseModel;
    info: ActuatorInfoModel;
  }>()
);

export const LoadAppSecuredData = createAction(
  '[Core - preload] Load app secured data'
);

export const LoadAppSecuredDataSuccess = createAction(
  '[Core - preload] Load app secured data success',
  props<{
    currentUserContextResponse: CurrentUserContextResponseModel;
    applications: ApplicationModel[];
    organizations: OrganizationMasterDataModel[];
    hasActiveLicense: boolean;
    aktivnaOrganizacija: OrganizacijaModel;
    spj: StrategijskaPoslovnaJedinicaModel;
    poslovnaGodina: PoslovnaGodinaModel;
  }>()
);

export const ReloadApplications = createAction(
  '[Core - application] Reload applications'
);

export const ReloadApplicationsSuccess = createAction(
  '[Core - application] Reload applications success',
  props<{ applications: ApplicationModel[] }>()
);
