import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActuatorRestService } from '@base/modules/rest/actuator/actuator-rest.service';
import { AuthenticationRestService } from '@base/modules/rest/authentication/authentication-rest.service';
import { CustomHttpInterceptor } from '@base/modules/rest/custom-http-interceptor';
import { BbsObjectRestService } from '@base/modules/rest/bbs-object/bbs-object-rest.service';
import { McmWizardRestService } from '@base/modules/rest/mcm-wizard/mcm-wizard-rest.service';
import { PermissionsRestService } from '@base/modules/rest/permissions/permissions-rest.service';
import { RegistrationRestService } from '@base/modules/rest/registration/registration-rest.service';
import { RolesRestService } from '@base/modules/rest/roles/roles-rest.service';
import { FilterService } from '@base/modules/rest/smart-reports/filter.service';
import { SmartReportRestService } from '@base/modules/rest/smart-reports/smart-report-rest.service';
import { UserGroupsRestService } from '@base/modules/rest/user-groups/user-groups-rest.service';
import { UserRestService } from '@base/modules/rest/user/user-rest.service';
import { CustomFieldRestService } from '@base/modules/rest/custom-field/custom-field-rest.service';
import { LayoutRestService } from '@base/modules/rest/layout/layout-rest.service';
import { LicenseRestAdapterService } from '@base/modules/rest/license/license-rest-adapter.service';
import { McmWizardRestAdapterService } from '@base/modules/rest/mcm-wizard/mcm-wizard-rest-adapter.service';
import { CustomFieldRestAdapterService } from '@base/modules/rest/custom-field/custom-field-rest-adapter.service';
import { LayoutRestAdapterService } from '@base/modules/rest/layout/layout-rest-adapter.service';
import {
  UserOnlineActivityRestAdapterService
} from '@base/modules/rest/user/user-online-activity-rest-adapter.service';
import {
  OrganizationDiagramRestAdapterService
} from '@base/modules/rest/organization/organization-diagram-rest-adapter.service';
import { ObjectAttachmentRestService } from '@base/modules/rest/object-attachment/object-attachment-rest.service';
import { PrintTemplateRestService } from '@base/modules/rest/print-template/print-template-rest.service';
import { FileRestUtilsService } from './common/file-rest-utils.service';
import { BeyondSuiteRestService } from './authentication/beyondsuite-rest.service';
import { UserOnlineActivityRestService } from './user/user-online-activity-rest.service';
import { PriceListRestService } from './price-list/price-list-rest.service';
import { ObjectRestServiceCreator } from './object/object-rest-service-creator';
import { UserGroupsRestAdapterService } from './user-groups/user-groups-rest-adapter.service';
import { UserRestAdapterService } from './user/user-rest-adapter.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    FileRestUtilsService,
    AuthenticationRestService,
    BeyondSuiteRestService,
    RegistrationRestService,
    UserRestService,
    ActuatorRestService,
    SmartReportRestService,
    FilterService,
    PermissionsRestService,
    RolesRestService,
    UserGroupsRestService,
    BbsObjectRestService,
    McmWizardRestService,
    CustomFieldRestService,
    LayoutRestService,
    UserOnlineActivityRestService,
    PriceListRestService,
    ObjectRestServiceCreator,
    UserGroupsRestAdapterService,
    UserRestAdapterService,
    McmWizardRestAdapterService,
    CustomFieldRestAdapterService,
    LayoutRestAdapterService,
    LicenseRestAdapterService,
    UserOnlineActivityRestAdapterService,
    OrganizationDiagramRestAdapterService,
    ObjectAttachmentRestService,
    PrintTemplateRestService
  ],
})
export class RestModule {
}
