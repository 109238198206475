import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BeyondPaginationModel } from '@base/model/common/beyond-pagination.model';
import { PaginationResponseModel } from '@base/model/common/pagination-response.model';
import { PageableRestService } from '@base/modules/rest/common/pageable-rest.service';
import { PermissionModel } from '@base/modules/rest/permissions/model/permission.model';
import { AppConfiguration } from 'app/app-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionsRestService implements PageableRestService<PermissionModel> {
  private readonly controllerUrl: string = this.appConfiguration.serverApiUrl + '/secured/permissions';

  constructor(private httpClient: HttpClient,
              private appConfiguration: AppConfiguration) {
  }

  getAll(): Observable<PermissionModel[]> {
    return this.httpClient.get<PermissionModel[]>(`${this.controllerUrl}/all`);
  }

  findAll(payload: { pagination: BeyondPaginationModel }): Observable<PaginationResponseModel<PermissionModel>> {
    const queryParams: Params = {
      page: payload.pagination.page,
      size: payload.pagination.size,
      sort: payload.pagination.sort,
      filter: payload.pagination.filter || '',
    };
    return this.httpClient.get<PaginationResponseModel<PermissionModel>>(this.controllerUrl, { params: queryParams });
  }

  findByIds(payload: { ids: number[] }): Observable<PermissionModel[]> {
    return this.httpClient.post<PermissionModel[]>(this.controllerUrl + '/ids', payload.ids);
  }

  findOne(payload: { id: number }): Observable<PermissionModel> {
    return this.httpClient.get<PermissionModel>(`${this.controllerUrl}/${payload.id}`);
  }

  create(payload: { permission: PermissionModel }): Observable<PermissionModel> {
    return this.httpClient.post<PermissionModel>(this.controllerUrl, payload.permission);
  }

  update(payload: { id: number; permission: PermissionModel }): Observable<PermissionModel> {
    return this.httpClient.put<PermissionModel>(`${this.controllerUrl}/${payload.id}`, payload.permission);
  }

  delete(payload: { ids: number[] }): Observable<void> {
    return this.httpClient.put<void>(`${this.controllerUrl}/delete-bulk`, payload.ids);
  }
}
