import { ApplicationNameEnum } from '@base/enums/application-name.enum';
import { ApplicationModel } from '@base/modules/rest/application/model/application.model';
import { CorePreloadActions } from '@base/store/preload';
import { IDENTITY_MENU } from '@base/utils/identity-menu.util';
import { createReducer, on } from '@ngrx/store';
import { ActuatorInfoModel } from '@base/modules/rest/actuator/model/actuator-info.model';

export interface State {
  applications: ApplicationModel[];
  info: ActuatorInfoModel;
}

const initialState: State = {
  applications: [],
  info: null,
};

export const reducer = createReducer(
  initialState,
  on(CorePreloadActions.LoadAppSecuredDataSuccess, (state: State, {applications}): State => {
    return updateApplicationsState(state, applications);
  }),
  on(CorePreloadActions.LoadAppPublicDataSuccess, (state: State, {info}): State => {
    return {
      ...state,
      info: {
        ...info,
        ldapActive: info.ldapActive as any === 'true',
        useFlexmonster: info.useFlexmonster as any === 'true' || info.useFlexmonster as any === true,
      },
    };
  }),
  on(CorePreloadActions.ReloadApplicationsSuccess, (state: State, {applications}): State => {
    return updateApplicationsState(state, applications);
  })
);

const updateApplicationsState = (state: State, applications: ApplicationModel[]): State => {
  const index = applications.findIndex(el => el.name === ApplicationNameEnum.BEYOND_IDENTITY);
  return {
    ...state,
    applications: [
      ...applications.slice(0, index),
      {
        ...applications[index],
        menu: IDENTITY_MENU,
      },
      ...applications.slice(index + 1),
    ],
  };

};
