import { Component } from '@angular/core';
import { HOME_PAGE } from '@base/services/app-routes.service';

@Component({
  selector: 'app-page-not-found-content',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  homeUrl = HOME_PAGE;
}
