import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class NavigationHistoryService {

  private _history: NavigationEnd[] = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        this._history.push(event as NavigationEnd);
      });
  }

  get history(): NavigationEnd[] {
    return this._history;
  }
}
