import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NO_PERMISSION_PAGE, NOT_FOUND_PAGE } from '@base/services/app-routes.service';
import { SnackBarService } from '@base/services/snackbar.service';
import { AppState } from '@base/store';
import { CoreUserActions } from '@base/store/user';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserContext } from '../../services/user-context';
import { getLanguage } from '../../utils/language.util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private store: Store<AppState>,
              private snackBarService: SnackBarService,
              private translate: TranslateService,
              private userContext: UserContext) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = this.cloneRequest(request);

    return next.handle(clonedRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            this.snackBarService.openErrorMessage('Server is down');
          }

          // Navigate to login page if unauthorized
          if (error.status === 401 && !clonedRequest.url.endsWith('is-logged-in')) {
            this.store.dispatch(CoreUserActions.Logout());
          }

          if (error.status === 403) {
            this.router.navigate([NO_PERMISSION_PAGE]);
          }

          if (error.status >= 500 && error.status < 600) {
            const responseMessage = error?.error?.message;
            const message = responseMessage || this.translate.instant('COMMON.INTERNAL_SERVER_ERROR');
            this.snackBarService.openErrorMessage(message);
          }

          if (error.status === 404) {
            this.router.navigate([NOT_FOUND_PAGE]);
          }

          return throwError(() => error);
        })
      );
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    const language = getLanguage();
    let headers: HttpHeaders = request.headers
      .set('active-profile', this.userContext.activeProfile)
      .set('language', language);

    if (this.userContext.aktivnaPoslovnaGodina) {
      headers = headers.set('activeFiscalYearId', `${this.userContext.aktivnaPoslovnaGodina?.id}`);
    }

    return request.clone({
      withCredentials: true,
      headers,
    });
  }
}
